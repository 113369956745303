import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HuddleService } from 'src/app/main/services/huddle.service';
import {
  App,
  Filters,
  Huddle,
  HuddleFilters,
  User,
  UserDetails,
} from 'src/app/main/types/main.types';
import moment from 'moment';
import { Observable, Subject, tap } from 'rxjs';
import {
  goingOptions,
  activityTypeOptions,
  participationTypeOptions,
  moreOptions,
} from 'src/app/main/utilities/overlayHelper';
import { Menu } from 'primeng/menu';
import { environment } from 'src/environments/environment';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import {
  ActivityEnum,
  activityMap,
  visibilityMap,
} from 'src/app/main/utilities';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/main/store/model/state.model';
import { formatEventDate } from 'src/app/main/utilities';

enum FilterType {
  T = 'TIMELINE',
  A = 'ACTIVITY_TYPE',
  P = 'PARTICIPATION_TYPE',
}

@Component({
  selector: 'app-huddle-huddles',
  templateUrl: './huddle-huddles.component.html',
})
export class HuddleHuddlesComponent implements OnInit {
  huddles: Huddle[] = [];

  selector = '.mat-drawer-content';

  groupDate: any = [];

  isDrawerOpen = false;

  inviteVisible = false;

  filterByDate: Date = new Date();

  filterHuddle = 'UPCOMING';

  defaultParticipationType: Filters;

  selectedDate: Date | null;

  participationType: Filters[];

  activityType: Filters[];

  defaultActivityType: Filters;

  currentPage = 1;

  limit = 20;

  huddleImage: string | null;

  interested = false;

  showInterestBtn = true;

  rsvpType = '';

  isLoading = !0;

  hasNext = false;

  goingOptions: MenuItem[] = goingOptions;

  moreOptions: any = moreOptions;

  drawerEvent: Subject<boolean> = new Subject<boolean>();

  moment = moment;

  huddleId: number | null = null;

  host: User | undefined;

  activityEnum = ActivityEnum;

  activityMap = activityMap;

  visibilityMap = visibilityMap;

  count: any = {};

  appState$: Observable<App>;

  isDark = false;

  userDetail$: Observable<UserDetails>;

  userInfo: UserDetails;

  startDateFrom: string | null = new Date().toISOString();

  startDateTo: string | null;

  sidebarEvent: { drawerOpen: boolean; isMobileScreen: boolean };

  @ViewChild('going', { static: false }) going: Menu;

  @ViewChild('menu', { static: false }) menu: Menu;

  shareLinkEvent: Subject<boolean> = new Subject<boolean>();

  constructor(
    private huddleService: HuddleService,
    private router: Router,
    private store: Store<AppState>,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.participationType = participationTypeOptions;
    this.activityType = activityTypeOptions;
    this.defaultActivityType = this.activityType[0];
    this.defaultParticipationType = this.participationType[0];
    this.getUserAndAppState();
    this.getAllHuddles(this.currentPage - 1, {
      startDateFrom: new Date().toISOString(),
      startDateTo: null,
      timeline: this.filterHuddle,
      activityType:
        this.defaultActivityType.code === 'ALL'
          ? null
          : this.defaultActivityType.code,
      participationType: 'ALL',
    });
  }

  getUserAndAppState() {
    this.appState$ = this.store
      .select((store) => store.app)
      .pipe(
        tap((appState: App) => {
          this.isDark = appState.isDark;
        }),
      );

    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.userInfo = user;
        }),
      );
    this.appState$.subscribe();
    this.userDetail$.subscribe();
  }

  getAllHuddles(page: number, filters: HuddleFilters) {
    this.huddleService.getAllHuddles(page, this.limit, filters).subscribe({
      next: (data: any) => {
        this.huddles = [...this.huddles, ...data.content];
        this.groupDate = this.groupByDate(this.huddles);
        this.hasNext = !data.last;
        this.huddles.forEach((h: Huddle) => {
          if (h.rsvpedHuddlers) {
            const id: any = h.id;
            this.count[id] = {
              totalInterested: h.rsvpedHuddlers.filter((h: any) => h.interested)
                .length,
              totalTentative: h.rsvpedHuddlers.filter(
                (h: any) => h.rsvpType === 'YES',
              ).length,
            };
          }
        });
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        console.error('Request to get all huddle errored out.' + err.message);
      },
    });
  }

  onDrawerOpen(event: boolean) {
    this.isDrawerOpen = event;
  }

  onClickCreate() {
    this.router.navigate(['huddle', 'create']);
  }

  groupByDate(data: any) {
    const obj: any = {};
    const groupHuddle: any = [];
    const dates: any = {};
    const currentDate = moment().format('YYYY-MM-DD');
    let hasToday = false;
    let todayTime = '';

    data.forEach((d: any) => {
      let _date;
      let huddleDate;
      if (d.startDateTime === null) {
        huddleDate = 'Not Scheduled';
        _date = 'Not Scheduled';
      } else {
        const date = moment(d.startDateTime);
        huddleDate = date.format('YYYY-MM-DD');

        _date =
          currentDate === huddleDate
            ? moment().calendar()
            : date.format('dddd, MMMM Do YYYY');

        if (currentDate === huddleDate) {
          hasToday = true;
          todayTime = _date;
        }
      }

      dates[huddleDate] = _date;

      if (obj[_date]) {
        obj[_date].push(d);
      } else {
        obj[_date] = [d];
      }
    });

    const keys = Object.keys(dates);

    // This sorting logic was wrong. Moreover, it was not required because the date coming from the server is already sorted.
    // keys.sort((a: any, b: any) => {
    //   if (a > b) return this.filterHuddle !== 'PAST' ? -1 : 1;
    //   if (a < b) return this.filterHuddle === 'PAST' ? 1 : -1;
    //   return 0;
    // });

    for (const sortedDate of keys) {
      groupHuddle.push({
        [dates[sortedDate]]: obj[dates[sortedDate]],
      });
    }

    if (!hasToday) {
      obj[moment().calendar()] = [];
      groupHuddle.unshift({
        [moment().calendar()]: [],
      });
    } else {
      const todayIndex = groupHuddle.findIndex(
        (group: any) => group[todayTime],
      );
      todayIndex > -1 &&
        (groupHuddle.unshift(groupHuddle[todayIndex]),
        groupHuddle.splice(todayIndex + 1, 1));
    }

    return groupHuddle;
  }

  onScrolledDown() {
    if (this.hasNext) {
      this.currentPage++;
      this.getAllHuddles(this.currentPage - 1, {
        startDateFrom: new Date().toISOString(),
        startDateTo: new Date().toISOString(),
        timeline: this.filterHuddle,
        activityType:
          this.defaultActivityType.code !== 'ALL'
            ? this.defaultActivityType.code
            : null,
        participationType: this.defaultParticipationType.code,
      });
    }
  }

  saveInterest(interested: boolean, id: number) {
    this.huddleService.saveInterest(id, interested).subscribe({
      next: (data: any) => {
        console.log('Request to join the huddle is successful.');
        if (data == true) {
          this.huddles = this.huddles.map((h: any) => {
            if (h.id === id) {
              h.selfHuddler.interested = interested;
            }
            return h;
          });
        }
      },
      error: (e) => {
        console.error('Request to join the huddle  errored out.');
        console.error(e);
      },
      complete: () => console.info('Request to join the huddle completed.'),
    });
  }

  saveRsvp(rsvpType: string, id = 0) {
    const _huddleId = this.huddleId ? this.huddleId : id;
    this.huddleService.saveRsvp(_huddleId, rsvpType).subscribe({
      next: (data: any) => {
        console.log('Request to RSVP the huddle is successful.');
        if (data == true) {
          this.huddles = this.huddles.map((h: any) => {
            if (h.id === _huddleId) {
              h.selfHuddler.rsvpType = rsvpType;
            }
            return h;
          });
        }
      },
      error: (e) => {
        console.error('Request to RSVP the huddle  errored out.');
        console.error(e);
      },
      complete: () => console.info('Request to RSVP the huddle completed.'),
    });
  }

  getKey(k: any) {
    return Object.keys(k)[0];
  }

  getValue(k: any) {
    return Object.values(k)[0];
  }

  toggle(event: any, huddle: Huddle, toggleFor: string) {
    toggleFor === 'GOING' && this.going.toggle(event);
    toggleFor === 'MORE' && this.menu.toggle(event);
    this.huddleId = huddle.id as number;
    this.host = huddle.host;
    this.rsvpType = huddle.selfHuddler?.rsvpType as string;
  }

  getCoverPhotoUrl(id: number) {
    return environment.apiUrl + '/api/huddles/' + id + '/cover';
  }

  openHuddle(e: any, id: number) {
    const isPrevent = e.target.classList.contains('prevent');
    if (isPrevent) return;
    this.router.navigate(['huddle', 'view', id]);
  }

  drawerOpenChanged(e: { drawerOpen: boolean; isMobileScreen: boolean }) {
    this.sidebarEvent = e;
  }

  onOverlayMenuClick(id: string) {
    if (id === 'copy_huddle_link') {
      this.shareLinkEvent.next(!0);
    } else if (id === 'interested') {
      this.saveInterest(!this.interested, this.huddleId as number);
    } else if (id === 'invite') {
      this.inviteVisible = true;
    } else if (id === 'cancel') {
      this.confirmationService.confirm({
        accept: () => {},
        reject: () => {},
      });
    }
  }

  onChangeFilters(e: any, filterType: string, filter?: string) {
    const timelines = ['UPCOMING', 'PAST', 'NO_DATE'];
    if (filter && filter !== 'CUSTOM_DATE') {
      this.startDateFrom =
        filter === 'NO_DATE' ? null : new Date().toISOString();
      this.startDateTo = timelines.includes(filter || '')
        ? null
        : new Date().toISOString();
    }

    if (timelines.includes(this.filterHuddle) && !filter) {
      this.startDateTo = null;
      this.startDateFrom =
        this.filterHuddle === 'NO_DATE' ? null : this.startDateFrom;
    }

    if (filter === 'CUSTOM_DATE') {
      const now = new Date().toISOString();
      this.startDateFrom = now;
      this.startDateTo = now;
    }

    this.isLoading = true;

    if (e instanceof Array) {
      this.startDateFrom = e[0];
      this.startDateTo = e[1] ? e[1] : this.startDateFrom;
    }

    if (filterType === FilterType.T && typeof e !== 'object') {
      this.filterByDate = new Date();
    }

    this.huddles = [];
    this.currentPage = 1;
    this.getAllHuddles(this.currentPage - 1, {
      startDateFrom: this.startDateFrom as string,
      startDateTo: this.startDateTo as string,
      timeline:
        filterType === FilterType.T && typeof e === 'string'
          ? e
          : this.filterHuddle,
      activityType:
        filterType === FilterType.A
          ? e.code === 'ALL'
            ? null
            : e.code
          : this.defaultActivityType.code === 'ALL'
            ? null
            : this.defaultActivityType.code,
      participationType:
        filterType === FilterType.P
          ? e.code
          : this.defaultParticipationType.code,
    });
  }

  resetAllFilters() {
    this.defaultActivityType = this.activityType[0];
    this.defaultParticipationType = this.participationType[0];
    this.filterHuddle = 'UPCOMING';
    this.filterByDate = new Date();
    this.huddles = [];
    this.currentPage = 1;
    this.isLoading = true;
    this.getAllHuddles(this.currentPage - 1, {
      startDateFrom: new Date().toISOString(),
      startDateTo: new Date().toISOString(),
      timeline: this.filterHuddle,
      activityType: null,
      participationType: this.defaultParticipationType.code,
    });
  }

  invite(e: any) {
    this.huddleService.invite('' + this.huddleId, e).subscribe({
      next: (data: any) => {
        this.messageService.add({
          severity: data.error ? 'error' : 'success',
          summary: 'Invite Huddle',
          detail: data.message,
        });
        this.inviteVisible = false;
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Invite Huddle',
          detail: err.error.message,
        });
      },
    });
  }

  cancelHuddle() {
    this.huddleService.cancelHuddle('' + this.huddleId).subscribe({
      next: (data: any) => {
        this.router.navigate(['/huddle', 'huddles']);
        this.messageService.add({
          severity: 'success',
          summary: 'Cancel Huddle',
          detail: 'Huddle has been cancelled',
        });
        this.huddles = this.huddles.filter(
          (huddle) => huddle.id !== this.huddleId,
        );
        this.groupDate = this.groupByDate(this.huddles);
        this.confirmationService.close();
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Cancel Huddle',
          detail: err.error.message,
        });
      },
    });
  }

  formatDate(startDate: string, endDate: string) {
    return formatEventDate(startDate, endDate);
  }
}
