<div
  class="bg-gray-100 dark:border-[#1F242F] dark:bg-[#1F242F] dark:text-slate-300"
>
  <div class="flex flex-col gap-4 p-4">
    <div class="text-xl">
      <p>Upcoming Huddles</p>
    </div>
    <div class="flex flex-shrink flex-wrap gap-6">
      <div class="shipping-item" *ngFor="let huddle of huddles">
        <p-card
          header="{{ huddle.name }}"
          subheader="{{ huddle.venue?.name }}"
          [style]="{ width: '330px' }"
        >
          <ng-template pTemplate="header">
            <img
              alt="Huddle Cover Photo"
              src="https://images.unsplash.com/photo-1682685797365-41f45b562c0a?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
          </ng-template>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore
          </p>
          <ng-template pTemplate="footer">
            <p-button
              routerLink="/huddle/view/{{ huddle.id }}"
              label="Open"
            ></p-button>
          </ng-template>
        </p-card>
      </div>
    </div>
  </div>
</div>
