import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  ElementRef,
  Inject,
  PLATFORM_ID,
  HostListener,
  AfterViewInit,
} from '@angular/core';
import { Location as LocationCommon } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaMatcher } from '@angular/cdk/layout';
import {
  ActivityDropdown,
  locationFields,
  Location,
} from 'src/app/main/utilities/formHelper';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import {
  faAngleLeft,
  faAngleRight,
  faImages,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import {
  initMap,
  scrollToErrorElement,
  validateImage,
} from 'src/app/main/utilities';
import { Countries, Label } from '../services/huddle.types';
import { HuddleService } from 'src/app/main/services/huddle.service';
import { MatSidenav } from '@angular/material/sidenav';
import data from 'src/configs/countries.json';
import {
  CropperPosition,
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
  LoadedImage,
} from 'ngx-image-cropper';
import { Community, User, UserDetails } from 'src/app/main/types/main.types';
import { Huddle } from 'src/app/main/types/main.types';
import { AppState } from 'src/app/main/store/model/state.model';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { cropperMenuOptions } from 'src/app/main/utilities/overlayHelper';
import { Editor, EditorInitEvent } from 'primeng/editor';
import { ActivityEnum } from 'src/app/main/utilities';
import { UserService } from 'src/app/main/services/user.service';
import { isPlatformBrowser } from '@angular/common';
import { CommunityService } from 'src/app/main/services/community.service';
import moment from 'moment';

enum SOURCE {
  GOOGLE = 'GOOGLE',
  MANUAL = 'MANUAL',
}

enum LOCATION {
  VENUE = 'V',
  MEETING = 'M',
  PARKING = 'P',
}

@Component({
  selector: 'app-huddle-create2',
  templateUrl: './huddle-create2.component.html',
})
export class HuddleCreate2Component
  implements OnInit, OnDestroy, AfterViewInit
{
  formGroup!: FormGroup;
  locationFormGroup!: FormGroup;
  faAngleLeft: IconDefinition = faAngleLeft;
  faAngleRight: IconDefinition = faAngleRight;
  faTrash: IconDefinition = faTrash;
  faImages: IconDefinition = faImages;
  contextSlider = false;
  mobileQuery: MediaQueryList;
  imageChangedEvent: any;
  visible = false;
  croppedImage: any = null;
  blob: Blob | null | undefined;
  file: File;
  isLoading = false;
  cropperPosition: CropperPosition;
  places: any = {};
  location = LOCATION;

  showLocation: {
    [LOCATION.VENUE]: boolean;
    [LOCATION.MEETING]: boolean;
    [LOCATION.PARKING]: boolean;
  } = {
    [LOCATION.VENUE]: false,
    [LOCATION.PARKING]: false,
    [LOCATION.MEETING]: false,
  };
  map: any;
  locationVisible: boolean;
  address: any = {};
  selectedLocation: string;
  createHuddleData: any = { venue: {}, parking: {}, meetingPoint: {} };
  source: any = {
    [LOCATION.VENUE]: SOURCE.GOOGLE,
    [LOCATION.MEETING]: SOURCE.GOOGLE,
    [LOCATION.PARKING]: SOURCE.GOOGLE,
  };
  coHosts: any = [];
  selectedActivity: ActivityDropdown;
  activity: ActivityDropdown[] = [];
  locationFields: Location[] = locationFields;
  countries: Countries[];
  label: Label[];
  startDate: Date = new Date();
  endDate: Date = new Date();
  submitted = false;
  eventFee: string;
  meetingPoint: string;
  parking: string;
  rsvpStartDate: string;
  rsvpEndDate: string;
  isContextOpen = true;
  isDrawerOpen: boolean;
  huddleSubject: Subject<any> = new Subject<any>();
  user: UserDetails;
  profilePic = '';
  checked = false;
  advancedFeatureChecked = false;
  isEventFeeChecked = false;
  isUserPickerVisible = false;
  entryFee = 'free';
  scale = 1;
  userDetail$: Observable<UserDetails>;
  transform: ImageTransform = {};
  ratio: number = 16 / 9;
  sourceImage: any = '';
  cropperMenuOptions = cropperMenuOptions;
  userPickerVisible = false;
  selectedUser: User[] = [];
  isBrowser = true;
  community: Community | null;
  moment = moment;
  communityId: string | null;
  huddlePartOfCommunity = false;
  userPickerEvent: Subject<any> = new Subject<any>();
  venueAddressSubject: Subject<any> = new Subject<any>();
  parkingAddressSubject: Subject<any> = new Subject<any>();
  meetingPointAddressSubject: Subject<any> = new Subject<any>();
  startTimePicker: { time: Date; hour: number; min: number };
  endTimePicker: { time: Date; hour: number; min: number };
  rsvpStartDatePicker: { time: Date; hour: number; min: number };
  rsvpEndDatePicker: { time: Date; hour: number; min: number };
  startDatePickerEvent: Subject<any> = new Subject<any>();
  endDatePickerEvent: Subject<any> = new Subject<any>();
  privacyOptions: {
    name: string;
    key: string;
    description: string;
    buttonFor: string;
  }[];
  showRadioButton = 'huddle';
  isDragging = false;
  isDraggingStart = false;
  lastMouseY = 0;
  translateY = 0;
  newTranslateY = 0;
  communities: Community[];
  endDateTimes: Label[];
  selectEndDateTime: Label;
  paymentforEvents: Label[];
  paymentMethods: Label[];
  currencies: Label[];
  attendeeLimitChecked = false;
  ageRanges: Label[];
  guestAllowedAges: string[];
  meetingPointChecked = false;
  rsvpChecked = false;
  parkingPointChecked = false;
  externalUrlChecked = false;
  @ViewChild('drawer', { static: false }) sideBarComponent: MatSidenav;
  @ViewChild('upload') uploadRef: ElementRef;
  @ViewChild('editor', { static: false }) editorRef: Editor;
  @ViewChild('image') imageRef: ElementRef;

  private _mobileQueryListener: () => void;
  constructor(
    private router: Router,
    private huddleService: HuddleService,
    private formBuilder: FormBuilder,
    private media: MediaMatcher,
    private changeDetectorRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private userService: UserService,
    private communityService: CommunityService,
    private _location: LocationCommon,
    @Inject(PLATFORM_ID) platformId: object,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.mobileQuery = this.media.matchMedia('(min-width: 1500px)');
    this._mobileQueryListener = () => {
      // !this.mobileQuery.matches && this.sideBarComponent.toggle();
      if (this.mobileQuery.matches) {
        this.sideBarComponent.open();
      } else {
        this.sideBarComponent.close();
      }
      this.changeDetectorRef.detectChanges();
    };
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);

    window.onresize = null;
    window.onresize = () => {
      if (window.innerWidth <= 768) {
        this.contextSlider = false;
      }
    };
  }

  ngOnInit(): void {
    this.loadQuillModule();
    this.activity = [
      { name: 'General', value: 'GENERIC' },
      { name: 'Hiking', value: 'HIKING' },
    ];
    this.label = [
      { name: 'Work', value: 'work' },
      { name: 'Home', value: 'home' },
      { name: 'Other', value: 'other' },
    ];
    this.endDateTimes = [
      { name: 'Specify end time', value: '-1' },
      { name: '30 min', value: '30' },
      { name: '1 hour', value: '1' },
      { name: '2 hour', value: '2' },
    ];
    this.privacyOptions = [
      {
        name: 'Public',
        key: 'PUBLIC',
        buttonFor: 'huddle',
        description: `The huddle will be visible to anyone on MissionPeak, including people who are not directly connected to you. It will appear in searches on the platform, and anyone can view the huddle details, RSVP, and share it with others.`,
      },
      {
        name: 'Connection',
        key: 'CONNECTION',
        buttonFor: 'huddle',
        description:
          'The huddle will only be visible to people within your network or those you are connected to. Only your connections can view the huddle details, RSVP, and interact with the huddle.',
      },
      {
        name: 'Private',
        key: 'PRIVATE',
        buttonFor: 'huddle',
        description: `The huddle will only be visible to the people you invite. Huddle details are not shared publicly or with your connections. Only invited guests can view the huddle, RSVP, and participate.`,
      },
      {
        name: 'Community',
        key: 'COMMUNITY',
        buttonFor: 'community',
        description: `Privacy of huddles of community is controlled by the privacy setting of the community`,
      },
    ];

    this.paymentforEvents = [
      { name: 'Free Event', value: 'FREE' },
      { name: 'Paid Event', value: 'PAID' },
      // { name: 'Ticketed Event', value: 'TICKETED' }
    ];

    this.paymentMethods = [
      { name: 'Direct, Cash or External', value: 'EXTERNAL' },
    ];

    this.currencies = [{ name: 'US$', value: 'USD' }];

    this.ageRanges = [
      { name: '0 to 4 years old', value: 'TODDLER' },
      { name: '4 to 12 years old', value: 'KID' },
      { name: '13 to 17 years old', value: 'TEEN' },
      { name: '18 to 65 years old', value: 'ADULT' },
      { name: '65+ years old', value: 'SENIOR' },
    ];

    this.selectEndDateTime = this.endDateTimes[3];
    this.formGroup = this.formBuilder.group({
      selectedActivity: [this.activity[0]],
      eventName: ['', Validators.required],
      eventDescription: [null],
      startDate: [null],
      endDate: [null],
      venueNote: [null],
      parkingNote: [null],
      meetingPointNote: [null],
      eventFee: [{ value: null, disabled: true }],
      rsvpStartAt: [null],
      rsvpEndAt: [null],
      [LOCATION.VENUE]: [null],
      [LOCATION.PARKING]: [null],
      [LOCATION.MEETING]: [null],
      label: [null],
      zip: [null],
      city: [null],
      state: [null],
      selectedCountry: [null],
      externalTrailUrl: [null],
      externalEventUrl: [null],
      externalPhotoAlbumUrl: [null],
      externalCommChannelUrl: [null],
      carpoolAllowed: [false],
      communityId: [null],
      privacy: [this.privacyOptions[0].key],
      paymentMode: [this.paymentforEvents[0].value],
      paymentMethod: [this.paymentMethods[0]],
      paymentCurrency: [this.currencies[0]],
      paymentAmount: [0],
      refundPolicy: [null],
      decisiveRsvp: [false],
      approvalRequired: [false],
      participantLimit: [0],
      participantVisible: [false],
      guestAllowed: [false],
      guestLimit: [0],
    });
    this.countries = Array.from(data);
    this.selectedActivity = this.activity[0];
    this.formGroup.get('label')?.valueChanges.subscribe(() => {
      this.showLocation[LOCATION.VENUE] = false;
    });

    const activity = this.activatedRoute.snapshot.queryParamMap.get('features');
    if (activity === 'general') {
      this.selectedActivity = this.activity[0];
    }

    const features = this.activatedRoute.snapshot.queryParamMap.get('features');
    if (features && features === 'carpooling') {
      this.formGroup.get('carpoolAllowed')?.patchValue(!0);
      this.advancedFeatureChecked = !0;
    }

    this.communityId =
      this.activatedRoute.snapshot.queryParamMap.get('communityId');
    if (this.communityId) {
      this.getCommunityBasic(this.communityId);
      this.showRadioButton = 'community';
      this.formGroup
        .get('privacy')
        ?.patchValue(this.privacyOptions[this.privacyOptions.length - 1].key);
    }

    this.locationFormGroup = this.formBuilder.group({
      name: [''],
      selectedLocationType: [this.locationFields[0]],
      selectedCountry: [
        { value: this.countries.find((c) => c.code === 'US'), disabled: true },
      ],
      addressLine1: [''],
      addressLine2: [''],
      city: [''],
      state: [''],
      zip: [''],
      latitude: [0],
      longitude: [0],
      altitude: [0],
      degree: [0],
      minutes: [0],
      seconds: [0],
    });
    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.user = user;
          this.profilePic =
            environment.apiUrl + '/api/user/' + this.user.id + '/pic';
        }),
      );
    this.userDetail$.subscribe();
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  async loadQuillModule() {
    if (!this.isBrowser) {
      return;
    }
  }

  async onEditorInit(e: EditorInitEvent) {
    if (this.isBrowser) {
      const { Delta } = await import('quill/core');
      e.editor.clipboard.addMatcher(
        Node.ELEMENT_NODE,
        function (node: HTMLElement) {
          return new Delta().insert(node.innerText);
        },
      );
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }

  onSelectDate(date: Date) {
    const _d = date.getDate();
    this.endDate.setDate(_d);
    this.endDate.setHours(2);
    this.formGroup.get('endDate')?.patchValue(null);
  }

  onSubmit() {
    this.submitted = true;
    const values = this.formGroup.value;

    if (values.startDate && this.startTimePicker) {
      const startTime = new Date(values.startDate);
      startTime.setHours(this.startTimePicker?.hour);
      startTime.setMinutes(this.startTimePicker?.min);
      values.startDate = startTime.toISOString();
    }

    if (this.selectEndDateTime.value !== '-1' && values.startDate) {
      const { name, value } = this.selectEndDateTime;
      const startTime = new Date(values.startDate);
      name.includes('h') &&
        startTime.setHours(startTime.getHours() + Number(value));
      name.includes('m') &&
        startTime.setMinutes(startTime.getMinutes() + Number(value));
      this.formGroup.get('endDate')?.patchValue(startTime.toISOString());
      values.endDate = startTime.toISOString();
    }

    if (values.endDate && this.endTimePicker) {
      const endTime = new Date(values.endDate);
      endTime.setHours(this.endTimePicker?.hour);
      endTime.setMinutes(this.endTimePicker?.min);
      values.endDate = endTime.toISOString();
    }

    if (values.rsvpStartAt && this.rsvpStartDatePicker) {
      const startTime = new Date(values.rsvpStartAt);
      startTime.setHours(this.rsvpStartDatePicker?.hour);
      startTime.setMinutes(this.rsvpStartDatePicker?.min);
      values.rsvpStartAt = startTime.toISOString();
    }

    if (values.rsvpEndAt && this.rsvpEndDatePicker) {
      const endTime = new Date(values.rsvpEndAt);
      endTime.setHours(this.rsvpEndDatePicker?.hour);
      endTime.setMinutes(this.rsvpEndDatePicker?.min);
      values.rsvpEndAt = endTime.toISOString();
    }

    if (values.startDate && new Date(values.startDate) < new Date()) {
      this.formGroup.get('startDate')?.setErrors({ pastStartDate: true });
      return;
    } else if (values.startDate) {
      this.formGroup.get('startDate')?.setErrors(null);
    }

    if (values.endDate && !values.startDate) {
      this.formGroup
        .get('startDate')
        ?.setErrors({ startDateRequiredForEndDate: true });
      return;
    } else if (values.startDate) {
      this.formGroup.get('startDate')?.setErrors(null);
    }

    if (
      values.endDate &&
      new Date(values.endDate) < new Date(values.startDate)
    ) {
      this.formGroup.get('endDate')?.setErrors({ smallerThanStartDate: true });
      return;
    } else if (values.startDate) {
      this.formGroup.get('endDate')?.setErrors(null);
    }

    if (values.eventFee && values.eventFee < 0) {
      this.formGroup.get('eventFee')?.setErrors({ negative: true });
    } else {
      this.formGroup.get('eventFee')?.setErrors(null);
    }

    if (
      values.participantLimit &&
      this.selectedUser.length + 1 > values.participantLimit
    ) {
      this.formGroup
        .get('participantLimit')
        ?.setErrors({ lessThanCoHostAndHost: true });
    } else {
      this.formGroup.get('participantLimit')?.setErrors(null);
    }

    if (values.paymentMode === 'PAID' && values.paymentAmount <= 0) {
      this.formGroup
        .get('paymentAmount')
        ?.setErrors({ paymentGreaterThanZero: true });
    } else {
      this.formGroup.get('paymentAmount')?.setErrors(null);
    }

    if (values.guestLimit && values.guestLimit > 1 && values.guestLimit > 5) {
      this.formGroup.get('guestLimit')?.setErrors({ limitFiveOrEqualTo: true });
    } else {
      this.formGroup.get('guestLimit')?.setErrors(null);
    }

    if (!this.formGroup.valid) {
      scrollToErrorElement();
    }

    if (this.formGroup.valid) {
      const data: Huddle = {
        communityId: values.communityId?.id || null,
        activity: values.selectedActivity.value,
        name: values.eventName,
        description: values.eventDescription,
        venueNote: values.venueNote,
        parkingNote: values.parkingNote,
        meetingPointNote: values.meetingPointNote,
        coverPhotoContentType: this.file?.type || null,
        coverPhotoName: this.file?.name || null,
        startDateTime: values.startDate,
        endDateTime: values.endDate,
        rsvpStartDateTime: values.rsvpStartAt,
        rsvpEndDateTime: values.rsvpEndAt,
        privacy: values.privacy,
        paymentMode: values.paymentMode,
        paymentMethod: values.paymentMethod?.value ?? null,
        paymentCurrency: values.paymentCurrency?.value ?? null,
        paymentAmount: values.paymentAmount,
        externalTrailUrl: values.externalTrailUrl,
        externalEventUrl: values.externalEventUrl,
        externalPhotoAlbumUrl: values.externalPhotoAlbumUrl,
        externalCommChannelUrl: values.externalCommChannelUrl,
        settings: {
          decisiveRsvp: values.decisiveRsvp,
          approvalRequired: values.approvalRequired,
          participantLimit: !values.participantLimit
            ? -1
            : values.participantLimit,
          participantVisible: !values.participantVisible,
          guestAllowed: values.guestAllowed,
          guestLimit: values.guestLimit,
          guestAllowedAges: this.guestAllowedAges,
        },
        features: values.carpoolAllowed ? ['CARPOOLING'] : null,
        coHostUserIDs: this.selectedUser.map((user) => user.id),
      };

      const isStartDateGreater =
        new Date(data.startDateTime) > new Date(data.endDateTime as string);
      const isStartAndEndDateSame =
        data.startDateTime === (data.endDateTime as string);

      if (isStartDateGreater && data.endDateTime) {
        this.messageService.add({
          severity: 'error',
          summary: 'Start Date',
          detail: 'Start Date must be smaller than end date',
        });
        return;
      }

      if (data.startDateTime && data.endDateTime && isStartAndEndDateSame) {
        this.messageService.add({
          severity: 'error',
          summary: 'Start Date and End Date',
          detail: 'Start Date and End Date must be different',
        });
        return;
      }

      if (Object.values(this.createHuddleData['venue']).length) {
        data.venue = this.createHuddleData['venue'];
      }

      if (Object.values(this.createHuddleData['parking']).length) {
        data.parking = this.createHuddleData['parking'];
      }

      if (Object.values(this.createHuddleData['meetingPoint']).length) {
        data.meetingPoint = this.createHuddleData['meetingPoint'];
      }

      if (this.communityId) {
        // data.communityId = Number(this.communityId);
        data.privacy = 'COMMUNITY';
      }
      this.createHuddle(data);
    }
  }

  onPaid(event: string) {
    this.eventFee = event;
  }

  contextOpen(event: boolean) {
    this.isContextOpen = event;
  }

  onCoHostChange(event: any) {
    this.coHosts = event;
    this.isUserPickerVisible = false;
  }

  rsvpDateChange(event: { value: string; field: string }): void {
    const { value, field } = event;
    if (field === 'startDate') {
      this.rsvpStartDate = value;
    } else {
      this.rsvpEndDate = value;
    }
  }

  drawerOpenChanged(e: boolean) {
    this.isDrawerOpen = e;
  }

  fileChangeEvent(event: any): void {
    if (event.target.files.length) {
      this.file = event.target.files[0];
      validateImage(this.file)
        .then(() => {
          this.imageChangedEvent = event;
          this.visible = true;
        })
        .catch((err) => {
          this.messageService.add(err);
        });
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.blob = event?.blob;
    this.cropperPosition = event.cropperPosition;
    this.sourceImage =
      event.base64 ||
      this.sanitizer.bypassSecurityTrustUrl(event.objectUrl as string);
  }

  imageLoaded(event: LoadedImage) {
    console.log(event);
  }

  cropperReady(dimensions: Dimensions) {
    console.log(dimensions);
  }

  onCropperClose() {
    this.visible = false;
    this.croppedImage = !!this.croppedImage;
  }

  onCropped() {
    this.visible = false;
    this.croppedImage = this.sourceImage;
  }

  createHuddle(body: Huddle) {
    this.isLoading = true;
    this.submitted = true;

    this.huddleService.createHuddle(body).subscribe({
      next: (data: any) => {
        console.log('Request to create a new huddle succeed.');
        const formData = new FormData();
        formData.append('file', this.blob as Blob);
        if (this.blob) {
          this.userService.compressImage(this.blob).then((_formData: any) => {
            _formData.append('y_coordinate', '' + this.translateY);
            this.huddleService
              .updateHuddleCoverPhoto(data.id, _formData)
              .subscribe({
                next: (data2: any) => {
                  console.log('Request to update cover photo succeeded.');
                  this.isLoading = false;
                  this.router.navigate(['huddle', 'view', data.id]);
                },
                error: (err) => {
                  this.isLoading = false;
                  console.error(
                    'Request to update cover photo errored out.' + err.message,
                  );
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Huddle',
                    detail:
                      'Huddle is created successfuly but image upload failed',
                  });
                  this.router.navigate(['huddle', 'view', data.id]);
                },
              });
          });
        } else {
          this.isLoading = false;
          this.router.navigate(['huddle', 'view', data.id]);
        }
      },
      error: (e) => {
        console.error('Request to create a new huddle errored out.');
        this.messageService.add({
          severity: 'error',
          summary: 'Huddle',
          detail: 'Something went wrong, please try again',
        });
        this.isLoading = false;
      },
      complete: () => console.info('Request to create a new huddle completed.'),
    });
  }

  editAndRemoveCroppedImage(action: string) {
    action === 'E' && (this.visible = true);
    action === 'D' &&
      ((this.croppedImage = null), (this.isDraggingStart = false));
  }

  resetLocation() {
    this.locationFormGroup.reset({
      name: '',
      selectedLocationType: this.locationFields[0],
      selectedCountry: null,
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      latitude: 0,
      longitude: 0,
      altitude: 0,
      degree: 0,
      minutes: 0,
      seconds: 0,
    });
    this.source[this.selectedLocation] = SOURCE.GOOGLE;
    this.showLocation[this.selectedLocation as LOCATION] = false;
    this.locationVisible = false;
  }

  showLocationDialog(addressType: LOCATION) {
    this.locationVisible = true;
    this.selectedLocation = addressType as string;
    // this.showLocation[addressType] = true;
  }

  gmSearchBox(el: any, map: any, type: LOCATION) {
    const {
      places: { SearchBox },
      LatLngBounds,
      LatLng,
    } = window.google.maps;
    const searchBox = new SearchBox(el);
    const bounds = map.getBounds();
    map.addListener('bounds_changed', () => {
      searchBox.setBounds(bounds || null);
    });
    searchBox.addListener('places_changed', () => {
      const [places] = searchBox.getPlaces() || [];
      const { address_components } = places;
      const { geometry } = places;
      const bounds = new LatLngBounds();
      const html = new DOMParser().parseFromString(
        places?.adr_address as string,
        'text/html',
      );
      const addressEl =
        html.querySelector('.street-address') ||
        html.querySelector('.extended-address') ||
        html.querySelector('.locality');
      const prop =
        type === LOCATION.VENUE
          ? 'venue'
          : type === LOCATION.MEETING
            ? 'meetingPoint'
            : 'parking';
      const latlng = new LatLng({
        lat: geometry?.location?.lat() as number,
        lng: geometry?.location?.lng() as number,
      });
      if (places.geometry?.viewport) {
        bounds.union(places.geometry.viewport);
      } else {
        bounds.extend(latlng);
      }
      map.fitBounds(bounds);
      this.places[type] = places;
      this.showLocation[type] = true;
      this.createHuddleData[prop].source = SOURCE.GOOGLE;
      this.createHuddleData[prop].refId = places.place_id;
      this.createHuddleData[prop].refUrl = places.url;
      this.createHuddleData[prop].name = places.name;
      this.createHuddleData[prop].tags = places.types?.join(', ');
      this.createHuddleData[prop].formattedAddress = places.formatted_address;
      this.createHuddleData[prop].latitude = geometry?.location?.lat();
      this.createHuddleData[prop].longitude = geometry?.location?.lng();
      this.createHuddleData[prop].streetAddress = addressEl?.innerHTML;
      this.createHuddleData[prop].postalCode = '';
      address_components?.forEach((add) => {
        if (add.types.includes('locality')) {
          this.formGroup.get('city')?.patchValue(add.long_name);
          this.createHuddleData[prop].locality = add.long_name;
        }
        if (add.types.includes('administrative_area_level_1')) {
          this.formGroup.get('state')?.patchValue(add.long_name);
          this.createHuddleData[prop].region = add.long_name;
        }
        if (add.types.includes('postal_code')) {
          this.formGroup.get('zip')?.patchValue(add.long_name);
          this.createHuddleData[prop].postalCode = add.long_name;
        }
        if (add.types.includes('route'))
          this.formGroup.get('addressLine1')?.patchValue(add.long_name);
        if (add.types.includes('country')) {
          this.formGroup
            .get('selectedCountry')
            ?.patchValue({ name: add.long_name, code: add.short_name });
          this.createHuddleData[prop].country = add.short_name;
        }
      });
      new google.maps.Marker({
        position: latlng,
        map,
      });
    });
  }

  onAddressChange(e: any, addressType: string) {
    const prop =
      addressType === LOCATION.VENUE
        ? 'venue'
        : addressType === LOCATION.MEETING
          ? 'meetingPoint'
          : 'parking';
    this.createHuddleData[prop] = e;
  }

  renderAddress() {
    this.venueAddressSubject.next({
      action: 'FORM',
      payload: {
        endAddress: this.createHuddleData[LOCATION.VENUE],
      },
    });
    this.parkingAddressSubject.next({
      action: 'FORM',
      payload: {
        endAddress: this.createHuddleData[LOCATION.PARKING],
      },
    });
    this.meetingPointAddressSubject.next({
      action: 'FORM',
      payload: {
        endAddress: this.createHuddleData[LOCATION.MEETING],
      },
    });
  }

  getProfileImage(id: number) {
    return environment.apiUrl + '/api/user/' + id + '/pic';
  }

  onDateSelect(e: any, dateType: string) {
    if (dateType === 'S') {
      this.formGroup.get('startDate')?.patchValue(e);
      this.formGroup.get('startDate')?.markAsTouched();
    } else if (dateType === 'R') {
      this.formGroup.get('endDate')?.patchValue(e);
      this.formGroup.get('endDate')?.markAsTouched();
    } else if (dateType === 'RS') {
      this.formGroup.get('rsvpStartAt')?.patchValue(e);
      this.formGroup.get('rsvpStartAt')?.markAsTouched();
    } else {
      this.formGroup.get('rsvpEndAt')?.patchValue(e);
      this.formGroup.get('rsvpEndAt')?.markAsTouched();
    }
  }

  onTimeSelect(e: any, dateType: string) {
    const hasStartDate = this.formGroup.get('startDate')?.value;
    if (dateType === 'S') {
      this.startTimePicker = e.value;
      hasStartDate && this.formGroup.get('startDate')?.setErrors(null);
    } else if (dateType === 'R') {
      this.endTimePicker = e.value;
    } else if (dateType === 'RS') {
      this.rsvpStartDatePicker = e.value;
    } else {
      this.rsvpEndDatePicker = e.value;
    }
  }

  onAcitivitySelect(e: any) {
    this.selectedActivity = e.value;
    if (
      e.value.value === ActivityEnum.Carpooling ||
      e.value.value === ActivityEnum.Hiking
    ) {
      this.formGroup.get('carpoolAllowed')?.patchValue(!0);
      this.advancedFeatureChecked = !0;
    }
  }

  onCropperDialogShow() {
    this.scale = 1;
  }

  zoomInAndOut(transform: string) {
    if (transform === 'in') this.scale += 0.1;
    else this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  onTieredMenuItemClick(action: string) {
    switch (action) {
      case 'image_delete':
        this.editAndRemoveCroppedImage('D');
        break;
      case 'image_upload':
        this.uploadRef.nativeElement.click();
    }
  }

  eventFeeChange(e: any) {
    if (e === 'free') {
      this.formGroup.get('eventFee')?.disable();
    } else {
      this.formGroup.get('eventFee')?.enable();
    }
  }

  onSelectedUser(user: User) {
    this.selectedUser.push(user);
  }

  onRemoveUser(id: number) {
    this.selectedUser = this.selectedUser.filter((u) => u.id !== id);
  }

  onSaveUser() {
    this.userPickerVisible = false;
  }

  onInviteClick() {
    this.userPickerVisible = true;
    this.userPickerEvent.next('onLoad');
    this.userPickerEvent.next(
      this.selectedUser.filter((u) => u.id !== this.user.id),
    );
    this.userPickerEvent.next(true);
  }

  onChangeLocation(addressType: string) {
    const prop =
      addressType === LOCATION.VENUE
        ? 'venue'
        : addressType === LOCATION.MEETING
          ? 'meetingPoint'
          : 'parking';
    this.createHuddleData[prop] = {};
  }

  //#region Community

  getCommunityBasic(id: string) {
    this.communityService.getCommunityBasic(id).subscribe({
      next: (data: any) => {
        this.community = data;
        this.communityId &&
          this.formGroup.get('communityId')?.patchValue(this.community);
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Huddle',
          detail: err.error.message,
        });
      },
    });
  }

  getLogoUrl(id: string) {
    return environment.apiUrl + '/api/communities/' + id + '/logo';
  }

  redirect(path: string[]) {
    this.router.navigate(path);
  }

  //#endregion Community

  back() {
    this._location.back();
  }

  reset() {
    this.formGroup.reset();
    this.venueAddressSubject.next({
      action: 'RESET',
    });
    this.parkingAddressSubject.next({
      action: 'RESET',
    });
    this.meetingPointAddressSubject.next({
      action: 'RESET',
    });
    this.editAndRemoveCroppedImage('D');
    this.startDatePickerEvent.next(null);
    this.endDatePickerEvent.next(null);
  }

  onMouseDown(event: MouseEvent): void {
    this.isDragging = true;
    this.lastMouseY = event.clientY;
    this.isDraggingStart = true;
    event.preventDefault();
  }

  onMouseMove(event: MouseEvent): void {
    if (this.isDragging) {
      const deltaY = event.clientY - this.lastMouseY;

      this.updatePosition(deltaY);

      this.lastMouseY = event.clientY;
    }
  }

  onMouseUp(): void {
    this.isDragging = false;
  }

  @HostListener('window:mouseup', ['$event'])
  onWindowMouseUp(event: MouseEvent): void {
    if (this.isDragging) {
      this.onMouseUp();
    }
  }

  @HostListener('window:mousemove', ['$event'])
  onWindowMouseMove(event: MouseEvent): void {
    if (this.isDragging) {
      this.onMouseMove(event);
    }
  }

  updatePosition(deltaY: number) {
    const wrapper = this.imageRef.nativeElement;
    const img = wrapper.querySelector('img');
    const maxTranslateY = wrapper.clientHeight - img.clientHeight;
    this.translateY = Math.min(
      0,
      Math.max(this.translateY + deltaY, maxTranslateY),
    );
  }

  getLeaderCommunities() {
    this.communityService.getCommunities(0, 'LEADER', '').subscribe({
      next: (data: any) => {
        this.communities = data.content;
      },
      error: (err: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Community',
          detail: err.error.message,
        });
      },
    });
  }

  onHuddlePartOfCommunityChange(data: any) {
    this.getLeaderCommunities();
    this.changeDetectorRef.detectChanges();
    if (data) {
      this.formGroup.get('communityId')?.setErrors({ required: true });
    } else {
      this.formGroup.get('communityId')?.setErrors(null);
      this.formGroup.get('communityId')?.patchValue(null);
      this.community = null;
      this.showRadioButton = 'huddle';
    }
  }

  onCommunitySelect(data: any) {
    this.getCommunityBasic(data.value.id);
    this.showRadioButton = 'community';
    this.formGroup
      .get('privacy')
      ?.patchValue(
        this.privacyOptions.find((privacy) => privacy.key === 'COMMUNITY')?.key,
      );
  }

  onEndDateTimes(data: any) {
    const { value, label } = data;
    if (value === '-1' && this.formGroup.get('startDate')?.value) {
      const startTime = new Date(this.formGroup.get('startDate')?.value);
      label.includes('h') && startTime.setHours(Number(value));
      label.includes('m') && startTime.setMinutes(Number(value));
      this.formGroup.get('startDate')?.patchValue(startTime.toISOString());
    }
  }

  get f() {
    return this.formGroup.controls;
  }
}
