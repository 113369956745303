import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Label } from '../../services/huddle.types';
import { HuddleService } from 'src/app/main/services/huddle.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-settings',
  templateUrl: './huddle-view-settings.component.html',
})
export class HuddleViewSettingsComponent implements OnInit {
  settingsFormGroup!: FormGroup;

  isLoading = true;

  privacyOptions: {
    name: string;
    key: string;
    description: string;
    buttonFor: string;
  }[];

  paymentforEvents: Label[];

  paymentMethods: Label[];

  currencies: Label[];

  ageRanges: Label[];

  guestAllowedAges: string[] | Label[];

  submitted = false;

  isEditMode = false;

  huddleId: string | null;

  attendeeLimitChecked = false;

  isCarpoolAllowed = false;

  communityRadioButton = 'huddle';

  constructor(
    private formBuilder: FormBuilder,
    private huddleService: HuddleService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.createOptions();
  }

  ngOnInit(): void {
    this.huddleId = this.activatedRoute.snapshot.paramMap.get('id');
    this.createFormGroup();
    this.disableEnableFields();
    this.getHuddle();
  }

  createFormGroup() {
    this.settingsFormGroup = this.formBuilder.group({
      privacy: [this.privacyOptions[0].key],
      paymentMode: [this.paymentforEvents[0].value],
      paymentMethod: [this.paymentMethods[0]],
      paymentCurrency: [this.currencies[0]],
      paymentAmount: [0],
      refundPolicy: [null],
      decisiveRsvp: [false],
      approvalRequired: [false],
      participantLimit: [0],
      participantVisible: [false],
      guestAllowed: [false],
      guestLimit: [0],
      carpoolAllowed: [false],
    });
  }

  createOptions() {
    this.privacyOptions = [
      {
        name: 'Public',
        key: 'PUBLIC',
        buttonFor: 'huddle',
        description: `The huddle will be visible to anyone on MissionPeak, including people who are not directly connected to you. It will appear in searches on the platform, and anyone can view the huddle details, RSVP, and share it with others.`,
      },
      {
        name: 'Connection',
        key: 'CONNECTION',
        buttonFor: 'huddle',
        description:
          'The huddle will only be visible to people within your network or those you are connected to. Only your connections can view the huddle details, RSVP, and interact with the huddle.',
      },
      {
        name: 'Private',
        key: 'PRIVATE',
        buttonFor: 'huddle',
        description: `The huddle will only be visible to the people you invite. Huddle details are not shared publicly or with your connections. Only invited guests can view the huddle, RSVP, and participate.`,
      },
      {
        name: 'Community',
        key: 'COMMUNITY',
        buttonFor: 'community',
        description: `Privacy of huddles of community is controlled by the privacy setting of the community`,
      },
    ];

    this.paymentforEvents = [
      { name: 'Free Event', value: 'FREE' },
      { name: 'Paid Event', value: 'PAID' },
      // { name: 'Ticketed Event', value: 'TICKETED' }
    ];

    this.paymentMethods = [
      { name: 'Direct, Cash or External', value: 'EXTERNAL' },
    ];

    this.currencies = [{ name: 'US$', value: 'USD' }];

    this.ageRanges = [
      { name: '0 to 4 years old', value: 'TODDLER' },
      { name: '4 to 12 years old', value: 'KID' },
      { name: '13 to 17 years old', value: 'TEEN' },
      { name: '18 to 65 years old', value: 'ADULT' },
      { name: '65+ years old', value: 'SENIOR' },
    ];
  }

  onSubmit() {}

  disableEnableFields(disable = true) {
    for (const v of Object.values(this.f)) {
      disable ? v.disable() : v.enable();
    }
  }

  edit() {
    this.disableEnableFields(!1);
    this.isEditMode = !0;
  }

  getHuddle() {
    this.huddleService.getHuddle(this.huddleId).subscribe({
      next: (data: any) => {
        const { settings, communityId } = data;
        this.isCarpoolAllowed = data.features.some(
          (u: any) => u.enabled && u.key === 'CARPOOLING',
        );
        this.communityRadioButton = communityId ? 'community' : 'huddle';
        this.settingsFormGroup.get('privacy')?.patchValue(data.privacy);
        this.settingsFormGroup.get('paymentMode')?.patchValue(data.paymentMode);
        this.settingsFormGroup
          .get('paymentMethod')
          ?.patchValue(
            this.paymentMethods.find(
              (method) => method.value === data.paymentMethod,
            ),
          );
        this.settingsFormGroup
          .get('paymentCurrency')
          ?.patchValue(
            this.currencies.find(
              (currency) => currency.value === data.paymentCurrency,
            ),
          );
        this.settingsFormGroup
          .get('paymentAmount')
          ?.patchValue(data.paymentAmount);
        this.settingsFormGroup
          .get('refundPolicy')
          ?.patchValue(data.refundPolicy);
        this.settingsFormGroup
          .get('decisiveRsvp')
          ?.patchValue(settings.decisiveRsvp);
        this.settingsFormGroup
          .get('approvalRequired')
          ?.patchValue(settings.approvalRequired);
        this.settingsFormGroup
          .get('participantLimit')
          ?.patchValue(settings.participantLimit);
        this.settingsFormGroup
          .get('participantVisible')
          ?.patchValue(!settings.participantVisible);
        this.settingsFormGroup
          .get('guestAllowed')
          ?.patchValue(settings.guestAllowed);
        this.settingsFormGroup
          .get('guestLimit')
          ?.patchValue(settings.guestLimit);
        this.settingsFormGroup
          .get('carpoolAllowed')
          ?.patchValue(this.isCarpoolAllowed);
        this.guestAllowedAges = settings.guestAllowedAges;
        this.attendeeLimitChecked =
          settings.participantLimit > 0 ? true : false;
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        console.error('Request to get huddle errored out.' + err.message);
      },
    });
  }

  get f() {
    return this.settingsFormGroup.controls;
  }
}
