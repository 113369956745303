import { Component, OnInit } from '@angular/core';

import { HuddleService } from 'src/app/main/services/huddle.service';
import { Huddle } from 'src/app/main/types/main.types';

@Component({
  selector: 'app-huddle-upcoming',
  templateUrl: './huddle-upcoming.component.html',
})
export class HuddleUpcomingComponent implements OnInit {
  huddles: Huddle[];

  constructor(private huddleService: HuddleService) {}

  ngOnInit(): void {
    this.huddleService.getAllHuddle().subscribe({
      next: (data: any) => {
        this.huddles = data;
      },
      error: (err) => {
        console.error('Request to get all huddle errored out.' + err.message);
      },
    });
  }

  onAddressChange(location: any) {
    console.log(location);
  }
}
