import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { HuddleService } from 'src/app/main/services/huddle.service';
import { initMap } from 'src/app/main/utilities';
import { faCar, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import {
  Carpool,
  Carpoolers,
  DialogEvent,
  Huddle,
  User,
  UserDetails,
} from 'src/app/main/types/main.types';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { Observable, Subject, Subscription } from 'rxjs';
import { ActivityEnum } from 'src/app/main/utilities';

@Component({
  selector: 'app-view-carpools',
  templateUrl: './huddle-view-carpool.component.html',
})
export class HuddleViewCarpoolComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  subscriptionRef: Subscription;
  faCar: IconDefinition = faCar;
  carpools: any = [];
  evenCarpools: any = [];
  oddCarpools: any = [];
  totalCarpoolers: number;
  totalCarpools: number;
  totalWaiting: number;
  isCreate = true;
  canCreateAndJoinCarpool = true;
  isViewMode = false;
  isLoading = true;
  waitList: User[] = [];
  isLeave = false;
  isPastHuddle = false;
  @Input() userInfo: UserDetails;
  @Input() huddleView: Huddle;
  @Input() huddleId: string | null;
  @Output() editCarpool = new EventEmitter();
  @Input() viewCarpoolEvent: Observable<any>;
  dialogEvent: Subject<DialogEvent> = new Subject<DialogEvent>();

  constructor(
    private huddleService: HuddleService,
    private messageService: MessageService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    // this.getCarpools();
    this.getWaitingList();
  }

  ngAfterViewInit(): void {
    this.subscriptionRef = this.viewCarpoolEvent.subscribe((data: any) => {
      this.huddleView = data;
      this.getCarpools();
    });
  }

  ngOnDestroy(): void {
    this.subscriptionRef.unsubscribe();
  }

  getCarpools() {
    if (this.huddleView.startDateTime) {
      this.isPastHuddle = new Date(this.huddleView.startDateTime) < new Date();
    }

    this.huddleService
      .getCarpools({
        parentEntity: 'HUDDLE',
        parentId: this.huddleId,
        includeCarpools: !0,
      })
      .subscribe({
        next: (data: any) => {
          const { carpools, totalCarpoolers, totalCarpools, totalWaiting } =
            data;
          const { venue } = this.huddleView;
          this.carpools = carpools;
          this.totalCarpoolers = totalCarpoolers;
          this.totalCarpools = totalCarpools;
          this.totalWaiting = totalWaiting;

          for (const carpool of this.carpools) {
            const isCarpooling = carpool.carpoolers.some(
              (c: Carpoolers) => c.member.id === this.userInfo.id,
            );
            if (isCarpooling) {
              this.canCreateAndJoinCarpool = !1;
              break;
            }
          }

          this.canCreateAndJoinCarpool =
            this.canCreateAndJoinCarpool &&
            this.huddleView.selfHuddler?.rsvpType === 'YES';

          if (!this.canCreateAndJoinCarpool && this.isLeave) {
            this.joinAndLeaveWaitlist(!1, !1);
          }

          this.evenCarpools = carpools.filter(
            (v: Carpool, i: number) => i % 2 === 0,
          );
          this.oddCarpools = carpools.filter(
            (v: Carpool, i: number) => i % 2 !== 0,
          );
          const addresses: { label: string; address: string }[] = [
            { label: 'V', address: venue?.formattedAddress },
          ] as { label: string; address: string }[];

          if (
            this.huddleView.activity === ActivityEnum.Carpooling &&
            this.huddleView.parking?.name
          ) {
            addresses.push({
              label: 'P',
              address: this.huddleView.parking.name,
            });
          }

          if (
            this.huddleView.activity === ActivityEnum.Hiking &&
            this.huddleView.meetingPoint?.name
          ) {
            addresses.push({
              label: 'M',
              address: this.huddleView.meetingPoint.name,
            });
          }

          if (
            !this.huddleView.parking?.name &&
            !this.huddleView.meetingPoint?.name
          ) {
            addresses.push({ label: '', address: 'New York, NY, USA' });
          }

          this.editCarpool.emit(this.canCreateAndJoinCarpool);
          initMap('gmap-carpool', addresses);
          this.isLoading = false;
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: '',
            detail: err.message,
          });
          this.isLoading = false;
          console.error('Request to create carpool errored out.' + err.message);
        },
      });
  }

  onHuddleData(e: any) {
    this.getCarpools();
  }

  getProfileImage(id: number) {
    return environment.apiUrl + '/api/user/' + id + '/pic';
  }

  requestToJoinCarpool(id: number) {
    this.huddleService.requestToJoin(id).subscribe({
      next: (data: any) => {
        this.getCarpools();
        this.messageService.add({
          severity: 'success',
          summary: 'Huddle Carpool',
          detail: data.message,
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: '',
          detail: err.message,
        });
        console.error('Request to create carpool errored out.' + err.message);
      },
    });
  }

  getWaitingList() {
    this.huddleService
      .getWaitlist({
        carpoolId: 0,
        carpoolParentId: this.huddleView.id,
        carpoolParentType: 'HUDDLE',
      })
      .subscribe({
        next: (data: any) => {
          this.waitList = data;
          this.isLeave = this.waitList.some((u) => u.id === this.userInfo.id);
          this.getCarpools();
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Waitlist',
            detail: err.error.message,
          });
        },
      });
  }

  joinAndLeaveWaitlist(isJoin = !0, showToast = !0) {
    if (!this.canCreateAndJoinCarpool) {
      this.messageService.add({
        severity: 'error',
        summary: 'Waitlist',
        detail: 'You are not allowed to join the waitlist.',
      });
      return;
    }

    const method = isJoin ? 'joinWaitlist' : 'leaveWaitlist';
    this.huddleService[method]({
      carpoolId: 0,
      carpoolParentId: this.huddleView.id,
      carpoolParentType: 'HUDDLE',
    }).subscribe({
      next: (data: any) => {
        showToast &&
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Waitlist',
            detail: data.message,
          });
        this.getWaitingList();
        this.isLeave = isJoin;
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Waitlist',
          detail: err.error.message,
        });
      },
    });
  }

  openCarpoolDialog() {
    if (!this.canCreateAndJoinCarpool) {
      this.messageService.add({
        severity: 'error',
        summary: 'Carpool',
        detail: 'You are not allowed to create a carpool.',
      });
      return;
    }

    this.isCreate = true;
    this.isViewMode = false;
    this.router.navigate(['/huddle/carpool/create'], {
      queryParams: { huddleId: this.huddleView.id },
    });
  }

  getProfileImageById(id: number) {
    return environment.apiUrl + '/api/user/' + id + '/pic';
  }

  onCarpoolClick(id: number) {
    this.router.navigate(['huddle', 'carpool', 'view', id]);
  }

  getVehicleCoverPhotoUrl(id: number | undefined) {
    return environment.apiUrl + '/api/vehicles/' + id + '/cover';
  }
}
