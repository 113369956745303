import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { HuddleService } from 'src/app/main/services/huddle.service';
import {
  Huddle,
  Huddlers,
  ParticipantSummary,
  User,
} from 'src/app/main/types/main.types';
import { roles } from 'src/app/main/utilities/sidebarHelper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-participants',
  templateUrl: './huddle-participants.component.html',
})
export class HuddleViewParticipantsComponent implements OnInit, OnChanges {
  selectButtonOptions: any[];

  isSkeletonLoading = true;

  isSkeletonUserLoading = true;

  selectedButton = 'confirmed';

  confirmed: Huddlers[];

  tenative: Huddlers[];

  interested: Huddlers[];

  participants: Huddlers[] = [];

  currentPage = 1;

  inviteType = 'users';

  summary: ParticipantSummary | undefined;

  roles: Record<string, string> = roles;

  @Input() huddleView: Huddle;

  @Input() selectedTab: string;

  @Input() participantEvent: Observable<string>;

  constructor(
    private huddleService: HuddleService,
    private messageService: MessageService,
  ) {
    this.selectButtonOptions = [
      {
        label: 'Confirmed',
        value: 'confirmed',
        prop: 'showRsvpedYesParticipants',
      },
      {
        label: 'Tenative',
        value: 'tenative',
        prop: 'showRsvpedMaybeParticipants',
      },
      {
        label: 'Interested',
        value: 'interested',
        prop: 'showInterestedParticipants',
      },
      { label: 'Invites', value: 'invites', prop: 'showInvitedParticipants' },
      {
        label: 'Declined',
        value: 'declined',
        prop: 'showRsvpedNoParticipants',
      },
      {
        label: 'Waiting List',
        value: 'waiting_list',
        prop: 'showRequestedParticipants',
      },
    ];
  }

  ngOnInit(): void {
    this.participantEvent.subscribe((tab: string) => {
      this.participants = [];
      this.summary = undefined;
      this.isSkeletonLoading = true;
      this.isSkeletonUserLoading = true;
      this.getParticipantsSummary();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['selectedTab'] &&
      changes['selectedTab'].previousValue !==
        changes['selectedTab'].currentValue
    ) {
      this.selectedButton = changes['selectedTab'].currentValue;
      this.onSelectAction({ value: this.selectedButton });
    }
  }

  onSelectAction(e: any) {
    this.participants = [];
    this.currentPage = 1;
    this.isSkeletonUserLoading = true;
    const value = e.value ? e.value : e;
    switch (value) {
      case 'confirmed':
        this.getRsvped('YES');
        break;
      case 'tenative':
        this.getRsvped('MAYBE');
        break;
      case 'interested':
        this.getInterested();
        break;
      case 'declined':
        this.getRsvped('NO');
        break;
      case 'invites':
        this.getInvitedUsers();
        break;
      case 'waiting_list':
        this.getWaitingList();
        break;
    }
  }

  getInvitedUsers() {
    this.huddleService
      .getInvitedUsers('' + this.huddleView.id, this.currentPage - 1)
      .subscribe({
        next: (data: any) => {
          this.participants = [...this.participants, ...data.content];
          this.isSkeletonUserLoading = false;
        },
        error: (err) => this.handleCatch(err),
      });
  }

  getRsvped(rsvpType: string) {
    this.huddleService
      .getRSVPed(rsvpType, '' + this.huddleView.id, this.currentPage - 1)
      .subscribe({
        next: (data: any) => {
          this.participants = [...this.participants, ...data.content];
          this.isSkeletonUserLoading = false;
        },
        error: (err) => this.handleCatch(err),
      });
  }

  getInterested() {
    this.huddleService
      .getInterested('' + this.huddleView.id, this.currentPage - 1)
      .subscribe({
        next: (data: any) => {
          this.participants = [...this.participants, ...data.content];
          this.isSkeletonUserLoading = false;
        },
        error: (err) => this.handleCatch(err),
      });
  }

  getWaitingList() {
    this.huddleService
      .getWaitingList('' + this.huddleView.id, this.currentPage - 1)
      .subscribe({
        next: (data: any) => {
          this.participants = [...this.participants, ...data.content];
          this.isSkeletonUserLoading = false;
        },
        error: (err) => this.handleCatch(err),
      });
  }

  handleCatch(err: any) {
    this.messageService.add({
      severity: 'error',
      summary: 'Huddle Participants',
      detail: err.message,
    });
    console.error('Request to get carpools errored out.' + err.message);
  }

  getProfileImageById(id: number) {
    return environment.apiUrl + '/api/user/' + id + '/pic';
  }

  getParticipantsSummary() {
    this.huddleService
      .getParticipantsSummary('' + this.huddleView.id)
      .subscribe({
        next: (data: any) => {
          this.summary = data;
          this.selectButtonOptions = this.selectButtonOptions.filter(
            (options) => {
              const prop = options.prop;
              if (data[prop]) {
                return options;
              }
            },
          );
          this.onSelectAction(this.selectedButton);
          this.isSkeletonLoading = false;
        },
        error: (err) => this.handleCatch(err),
      });
  }

  onScrolledDown() {
    this.currentPage++;
  }
}
