<div class="surface-ground" *ngIf="!isLoading">
  <div class="mx-auto max-w-[920px] p-2">
    <section
      id="upcoming"
      class="mb-4"
      *ngIf="!confirmedHuddle && !hostingHuddle"
    >
      <div class="linear max mx-auto transition-all duration-500">
        <h2 class="h-level-2 mb-2">Upcoming Huddle</h2>
        <div class="shipping-item shrink grow basis-0 p-[4px]">
          <p-card
            class="mx-auto block md:mx-0 md:my-auto"
            styleClass="padding-clear carpool-list"
          >
            <div class="flex flex-col md:flex-row">
              <div class="flex-1">
                <div
                  class="grid cursor-pointer grid-cols-1 gap-x-2 gap-y-3 px-4 py-4 sm:grid-cols-6"
                >
                  <div class="col-span-6 md:col-span-6">
                    <h3 class="h-level-3">No upcoming huddle</h3>
                    <p>You do not have any upcoming huddle</p>
                    <div class="my-3 text-center">
                      <button
                        type="button"
                        pButton
                        (click)="
                          redirect(['/huddle', 'huddles'], 'huddle_huddles')
                        "
                        class="mp-button mp-button-outlined mb-4 truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
                      >
                        <span>Explore Huddles</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <p-divider
                layout="vertical"
                styleClass="mp-divider md:block hidden"
              ></p-divider>
              <p-divider styleClass="mp-divider block md:hidden"></p-divider>
              <div
                class="grid flex-1 cursor-pointer grid-cols-1 gap-x-2 gap-y-3 px-4 py-4 sm:grid-cols-6"
              >
                <div class="col-span-6 md:col-span-6">
                  <h3 class="h-level-3">Be the host</h3>
                  <p>
                    Create a new huddle and invite friends to do something fun
                    and stay active
                  </p>
                  <div class="my-3 text-center">
                    <button
                      type="button"
                      pButton
                      (click)="redirect(['/huddle', 'create'], 'huddle_create')"
                      class="mp-button mp-button-outlined mb-4 truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
                    >
                      <span>Create Huddle</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </p-card>
        </div>
      </div>
    </section>

    <section id="upcoming" class="mb-4" *ngIf="confirmedHuddle">
      <div class="linear max mx-auto transition-all duration-500">
        <h2 class="h-level-2 mb-2">Upcoming Huddle</h2>
        <div class="shipping-item shrink grow basis-0 p-[4px]">
          <p-card
            class="mx-auto block md:mx-0 md:my-auto"
            styleClass="padding-clear carpool-list"
          >
            <ng-template pTemplate="header">
              <div class="flex grow items-center justify-between border-b p-3">
                <div class="flex items-center">
                  <p-avatar styleClass="mr-2" size="large" shape="circle">
                    <img
                      *ngIf="confirmedHuddle.host?.profilePicUrl"
                      [src]="profilePicUrl | image | async"
                      alt=""
                    />
                    <img
                      *ngIf="!confirmedHuddle.host?.profilePicUrl"
                      src="../assets/images/user-pic-default.svg"
                      alt="default"
                    />
                  </p-avatar>
                  <div class="">
                    <span>You</span>
                    <span>
                      are
                      <strong class="primary">{{
                        confirmedHuddle.host?.id === userInfo.id
                          ? "Hosting"
                          : isCohostingUpcoming
                            ? "CoHosting"
                            : "Attending"
                      }}</strong>
                      this
                      <strong class="primary">{{
                        activityMap[confirmedHuddle.activity]
                      }}</strong>
                      Huddle</span
                    >
                  </div>
                </div>
                <button
                  pButton
                  class="prevent mx-3 h-[32px] rounded-full p-1"
                  (click)="toggle($event, confirmedHuddle)"
                >
                  <span class="material-icons prevent"> more_horiz </span>
                </button>
              </div>
            </ng-template>
            <div class="flex flex-col md:flex-row">
              <div
                class="flex-1"
                (click)="
                  redirect(
                    ['/huddle', 'view', '' + confirmedHuddle.id],
                    'open_huddle'
                  )
                "
              >
                <div
                  class="grid cursor-pointer grid-cols-1 gap-x-2 gap-y-3 py-2 pl-2 pr-2 sm:grid-cols-6 md:pr-0"
                >
                  <div class="col-span-6 md:col-span-6">
                    <div
                      class="w-[calc(-243.101px + 74.8004vh)] min-w-full max-w-full"
                    >
                      <div class="relative h-0 overflow-hidden pt-[50.689%]">
                        <div class="absolute left-[0%] top-0 h-full w-full">
                          <img
                            alt=""
                            height="1127"
                            width="843"
                            class="absolute bottom-0 left-0 right-0 top-0 h-full w-full object-cover"
                            *ngIf="confirmedHuddle.coverPhotoUrl"
                            [src]="
                              getHuddleCoverImage(confirmedHuddle.id)
                                | image
                                | async
                            "
                          />
                          <img
                            *ngIf="!confirmedHuddle.coverPhotoUrl"
                            [src]="huddleImage"
                            alt="default"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-6 md:col-span-6">
                    <span class="highlight-text uppercase"
                      >{{
                        confirmedHuddle.startDateTime
                          | date: "EEEE, MMMM d, y, h:mm a"
                      }}
                      {{ confirmedHuddle.endDateTime ? "-" : "" }}
                      {{
                        confirmedHuddle.endDateTime
                          | date: "EEEE, MMMM d, y, h:mm a"
                      }}</span
                    >
                  </div>
                  <div class="col-span-6 md:col-span-6">
                    <span class=""
                      ><strong>Activity:</strong>
                      {{ activityMap[confirmedHuddle.activity] }}</span
                    >
                  </div>
                  <div class="col-span-6 md:col-span-6">
                    <h3 class="h-level-3 !font-semibold">
                      {{ confirmedHuddle.name }}
                    </h3>
                  </div>
                  <div
                    class="col-span-6 md:col-span-6"
                    *ngIf="confirmedHuddle.venue"
                  >
                    <h4 class="h-level-4">
                      {{
                        confirmedHuddle.venue.name
                          ? confirmedHuddle.venue.name
                          : "No Venue Selected"
                      }}
                    </h4>
                  </div>
                  <div class="col-span-6 md:col-span-6">
                    <div
                      class="mb-1 flex grow flex-col items-start md:flex-row md:items-center"
                    >
                      <div class="flex flex-1 flex-col gap-3">
                        <span class="flex items-center gap-3">
                          <p-avatarGroup>
                            <p-avatar
                              *ngFor="
                                let huddler of confirmedHuddle.rsvpedHuddlers
                              "
                              shape="circle"
                              size="large"
                              styleClass="inline-block align-middle"
                            >
                              <img
                                *ngIf="huddler.user.profilePicUrl"
                                [src]="
                                  getProfileImage(huddler.user.id)
                                    | image
                                    | async
                                "
                                alt=""
                              />
                              <img
                                *ngIf="!huddler.user.profilePicUrl"
                                src="../assets/images/user-pic-default.svg"
                                alt="default"
                              />
                            </p-avatar>
                            <p-avatar
                              *ngIf="confirmedHuddle.rsvpedHuddlers.length > 7"
                              [label]="
                                '+' +
                                (
                                  confirmedHuddle.rsvpedHuddlers.length - 7
                                ).toString()
                              "
                              shape="circle"
                            ></p-avatar>
                          </p-avatarGroup>
                          <span>going</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p-divider
                layout="vertical"
                styleClass="mp-divider md:block hidden m-2 mt-0"
              ></p-divider>
              <p-divider styleClass="mp-divider block md:hidden"></p-divider>
              <div
                class="grid flex-1 cursor-pointer grid-cols-1 gap-x-2 gap-y-3 px-4 py-4 sm:grid-cols-6"
              >
                <div class="col-span-6 md:col-span-6">
                  <h2 class="h-level-2">AI Assisted Smart Instructions</h2>
                </div>
                <div class="col-span-6 md:col-span-6">
                  <div class="mb-2">
                    <span class="form-title">Summary</span>
                    <p-skeleton
                      *ngIf="
                        isSkeletonLoading && !intructionsUpcoming?.huddleSummary
                      "
                      styleClass="mb-2"
                    ></p-skeleton>
                    <p-skeleton
                      *ngIf="
                        isSkeletonLoading && !intructionsUpcoming?.huddleSummary
                      "
                      styleClass="mb-2"
                    ></p-skeleton>
                    <p
                      *ngIf="
                        !isSkeletonLoading && intructionsUpcoming?.huddleSummary
                      "
                    >
                      {{ intructionsUpcoming.huddleSummary }}
                    </p>
                  </div>
                  <div
                    class="mb-2"
                    *ngFor="
                      let _instruction of intructionsUpcoming?.huddleInstructions
                    "
                  >
                    <span class="form-title">{{ _instruction.heading }}</span>
                    <p-skeleton
                      *ngIf="isSkeletonLoading"
                      styleClass="mb-2"
                    ></p-skeleton>
                    <p-skeleton
                      *ngIf="isSkeletonLoading"
                      styleClass="mb-2"
                    ></p-skeleton>
                    <p *ngIf="!isSkeletonLoading">
                      {{ _instruction.instruction }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </p-card>
        </div>
      </div>
    </section>

    <section id="hosting-next" class="mb-4" *ngIf="isNextHosting">
      <div class="linear max mx-auto transition-all duration-500">
        <h2 class="h-level-2 mb-2">Hosting Next</h2>
        <div class="shipping-item shrink grow basis-0 p-[4px]">
          <p-card
            class="mx-auto block md:mx-0 md:my-auto"
            styleClass="padding-clear carpool-list"
          >
            <ng-template pTemplate="header">
              <div class="flex grow items-center justify-between border-b p-3">
                <div class="flex items-center">
                  <p-avatar styleClass="mr-2" size="large" shape="circle">
                    <img
                      *ngIf="hostingHuddle.host?.profilePicUrl"
                      [src]="
                        getProfileImage(hostingHuddle.host?.id) | image | async
                      "
                      alt=""
                    />
                    <img
                      *ngIf="!hostingHuddle.host?.profilePicUrl"
                      src="../assets/images/user-pic-default.svg"
                      alt="default"
                    />
                  </p-avatar>
                  <div class="">
                    <span>You</span>
                    <span>
                      are
                      <strong class="primary">{{
                        hostingHuddle.host?.id === userInfo.id
                          ? "Hosting"
                          : isCoHostingNext
                            ? "CoHosting"
                            : "Attending"
                      }}</strong>
                      this
                      <strong class="primary">{{
                        activityMap[hostingHuddle.activity]
                      }}</strong>
                      Huddle</span
                    >
                  </div>
                </div>
                <button pButton class="prevent mx-3 h-[32px] rounded-full p-1">
                  <span class="material-icons prevent"> more_horiz </span>
                </button>
              </div>
            </ng-template>
            <div class="flex flex-col md:flex-row">
              <div class="flex-1">
                <div
                  class="grid cursor-pointer grid-cols-1 gap-x-2 gap-y-3 py-2 pl-2 pr-0 sm:grid-cols-6"
                >
                  <div class="col-span-6 md:col-span-6">
                    <div
                      class="w-[calc(-243.101px + 74.8004vh)] min-w-full max-w-full"
                    >
                      <div class="relative h-0 overflow-hidden pt-[50.689%]">
                        <div class="absolute left-[0%] top-0 h-full w-full">
                          <img
                            alt=""
                            height="1127"
                            width="843"
                            class="absolute bottom-0 left-0 right-0 top-0 h-full w-full object-cover"
                            *ngIf="hostingHuddle.coverPhotoUrl"
                            [src]="
                              getHuddleCoverImage(hostingHuddle.id)
                                | image
                                | async
                            "
                          />
                          <img
                            *ngIf="!hostingHuddle.coverPhotoUrl"
                            [src]="huddleImage"
                            alt="default"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-6 md:col-span-6">
                    <span class="highlight-text uppercase"
                      >{{
                        hostingHuddle.startDateTime
                          | date: "EEEE, MMMM d, y, h:mm a"
                      }}
                      -
                      {{
                        hostingHuddle.endDateTime
                          | date: "EEEE, MMMM d, y, h:mm a"
                      }}</span
                    >
                  </div>
                  <div class="col-span-6 md:col-span-6">
                    <span class="h-level-3">{{ hostingHuddle.name }}</span>
                  </div>
                  <div class="col-span-6 md:col-span-6">
                    <div
                      class="mb-1 flex grow flex-col items-start md:flex-row md:items-center"
                    >
                      <div class="flex flex-1 flex-col gap-3">
                        <span class="flex items-center gap-3">
                          <p-avatarGroup>
                            <p-avatar
                              *ngFor="
                                let huddler of hostingHuddle.rsvpedHuddlers
                              "
                              image="../assets/images/user-pic-default.svg"
                              shape="circle"
                              size="large"
                              styleClass="inline-block align-middle"
                            >
                              <img
                                *ngIf="huddler.user.profilePicUrl"
                                [src]="
                                  getProfileImage(huddler.user.id)
                                    | image
                                    | async
                                "
                                alt=""
                              />
                              <img
                                *ngIf="!huddler.user.profilePicUrl"
                                src="../assets/images/user-pic-default.svg"
                                alt="default"
                              />
                            </p-avatar>
                            <p-avatar
                              *ngIf="hostingHuddle.rsvpedHuddlers.length > 7"
                              [label]="
                                '+' +
                                (
                                  hostingHuddle.rsvpedHuddlers.length - 7
                                ).toString()
                              "
                              shape="circle"
                            ></p-avatar>
                          </p-avatarGroup>
                          <span>going</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p-divider
                layout="vertical"
                styleClass="mp-divider md:block hidden m-2 mt-0"
              ></p-divider>
              <p-divider styleClass="mp-divider block md:hidden"></p-divider>
              <div
                class="grid flex-1 cursor-pointer grid-cols-1 gap-x-2 gap-y-3 px-4 py-4 sm:grid-cols-6"
              >
                <div class="col-span-6 md:col-span-6">
                  <h2 class="h-level-2">AI Assisted Smart Instructions</h2>
                </div>
                <div class="col-span-6 md:col-span-6">
                  <div class="mb-2">
                    <span class="form-title">Summary</span>
                    <p-skeleton
                      *ngIf="isSkeletonLoading"
                      styleClass="mb-2"
                    ></p-skeleton>
                    <p-skeleton
                      *ngIf="isSkeletonLoading"
                      styleClass="mb-2"
                    ></p-skeleton>
                    <p *ngIf="!isSkeletonLoading">
                      {{ instructionsHosting.huddleSummary }}
                    </p>
                  </div>
                  <div
                    class="mb-2"
                    *ngFor="
                      let _instruction of instructionsHosting?.huddleInstructions
                    "
                  >
                    <span class="form-title">{{ _instruction.heading }}</span>
                    <p-skeleton
                      *ngIf="isSkeletonLoading"
                      styleClass="mb-2"
                    ></p-skeleton>
                    <p-skeleton
                      *ngIf="isSkeletonLoading"
                      styleClass="mb-2"
                    ></p-skeleton>
                    <p *ngIf="!isSkeletonLoading">
                      {{ _instruction.instruction }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </p-card>
        </div>
      </div>
    </section>

    <div
      *ngIf="confirmedHuddle || isNextHosting"
      class="-mt-4 mb-4 flex justify-end"
    >
      <span class="h-level-3">Explore more at&nbsp;</span>
      <a class="primary h-level-3" [routerLink]="['/huddle', 'huddles']">
        Huddles
      </a>
    </div>

    <section id="upcoming-carpool" class="mb-4">
      <div class="linear max mx-auto mb-4 transition-all duration-500">
        <h2 class="h-level-2 mb-2">Upcoming Carpool</h2>
        <div class="shipping-item shrink grow basis-0 p-[4px]">
          <p-card
            class="mx-auto block md:mx-0 md:my-auto"
            styleClass="padding-clear carpool-list"
          >
            <ng-template pTemplate="header">
              <div
                class="flex grow items-center justify-between border-b p-3"
                *ngIf="carpool"
              >
                <div class="flex items-center">
                  <p-avatar styleClass="mr-2" size="large" shape="circle">
                    <img
                      *ngIf="carpool.leader?.profilePicUrl"
                      [src]="profilePicUrl | image | async"
                      alt=""
                    />
                    <img
                      *ngIf="!carpool.leader.profilePicUrl"
                      src="../assets/images/user-pic-default.svg"
                      alt="default"
                    />
                  </p-avatar>
                  <div class="">
                    <p-button
                      *ngIf="userInfo.id !== carpool.leader.id"
                      [label]="carpool.leader.displayName"
                      styleClass="prevent primary"
                      [text]="true"
                    ></p-button>
                    <span *ngIf="userInfo.id === carpool.leader.id">You</span>

                    <span
                      >{{
                        userInfo.id === carpool.leader.id ? " are" : " is"
                      }}
                      leading this carpool to&nbsp;</span
                    >
                    <p-button
                      *ngIf="carpool.parentObj"
                      [label]="carpool.parentObj.name"
                      styleClass="prevent primary"
                      [text]="true"
                    ></p-button>
                    <p-button
                      *ngIf="!carpool.parentObj"
                      [label]="carpool.startAddress.name"
                      styleClass="prevent primary"
                      [text]="true"
                      (click)="openMap(carpool.startAddress.name)"
                    ></p-button>
                  </div>
                </div>
                <button
                  (click)="openMenu($event, carpool)"
                  pButton
                  class="prevent mx-3 h-[32px] rounded-full p-1"
                >
                  <span class="material-icons prevent"> more_horiz </span>
                </button>
              </div>
            </ng-template>
            <div class="flex flex-col md:flex-row" *ngIf="carpool">
              <div
                class="flex-1"
                (click)="
                  redirect(
                    ['huddle', 'carpool', 'view', '' + carpool.id],
                    'open_carpool'
                  )
                "
              >
                <div
                  class="grid cursor-pointer grid-cols-1 gap-x-2 gap-y-3 px-4 py-4 sm:grid-cols-6"
                >
                  <div class="col-span-6 md:col-span-6">
                    <span class="highlight-text">
                      <span class="form-title">Start Time:</span>
                      {{ carpool.startTime | date: "EEE, MMM d, h:mm a" }}
                    </span>
                  </div>
                  <div
                    class="col-span-6 md:col-span-6"
                    *ngIf="carpool.returnTime"
                  >
                    <span class="highlight-text">
                      <span class="form-title">Return Time:</span>
                      {{ carpool.returnTime | date: "EEE, MMM d, h:mm a" }}
                    </span>
                  </div>
                  <div class="col-span-6 md:col-span-6">
                    <span
                      ><span class="form-title">Start Address:</span>
                      {{ carpool.startAddress.name }}</span
                    >
                  </div>
                  <div class="col-span-6 md:col-span-6">
                    <span
                      ><span class="form-title">End Address:</span>
                      {{ carpool.endAddress.name }}</span
                    >
                  </div>
                  <div class="col-span-6 md:col-span-6">
                    <span
                      ><span class="form-title">Trip Type:</span>
                      {{
                        carpool.tripType === "ROUND"
                          ? "Round Trip"
                          : carpool.tripType === "DEPART"
                            ? "Depart Trip"
                            : "Return Trip"
                      }}</span
                    >
                  </div>
                  <div class="col-span-6 md:col-span-6">
                    <div
                      class="mb-1 flex grow flex-col items-start md:flex-row md:items-center"
                    >
                      <div class="flex flex-1 flex-col gap-3">
                        <span class="flex items-center gap-3">
                          <p-avatarGroup>
                            <p-avatar
                              *ngFor="
                                let carpooler of carpool.carpoolers.slice(0, 5)
                              "
                              shape="circle"
                              styleClass="inline-block align-middle"
                            >
                              <img
                                *ngIf="carpooler.member.profilePicUrl"
                                [src]="
                                  getProfileImage(carpooler.member.id)
                                    | image
                                    | async
                                "
                                alt=""
                              />
                              <img
                                *ngIf="!carpooler.member.profilePicUrl"
                                src="../assets/images/user-pic-default.svg"
                                alt="default"
                              />
                            </p-avatar>
                            <p-avatar
                              *ngIf="carpool.carpoolers.length > 5"
                              [label]="
                                '+' + (carpool.carpoolers.length - 5).toString()
                              "
                              shape="circle"
                            ></p-avatar>
                          </p-avatarGroup>
                          <span
                            >{{ carpool.carpoolers.length }} carpoolers</span
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p-divider
                layout="vertical"
                styleClass="mp-divider md:block hidden"
              ></p-divider>
              <p-divider styleClass="mp-divider block md:hidden"></p-divider>
              <div
                class="grid flex-1 cursor-pointer grid-cols-1 gap-x-2 gap-y-3 px-4 py-4 sm:grid-cols-6"
              >
                <div class="col-span-6 md:col-span-6">
                  <h2 class="h-level-2">AI Assisted Smart Instructions</h2>
                </div>
                <div class="col-span-6 md:col-span-6">
                  <div class="mb-2">
                    <span class="form-title">Summary</span>
                    <p-skeleton
                      *ngIf="isSkeletonLoading"
                      styleClass="mb-2"
                    ></p-skeleton>
                    <p-skeleton
                      *ngIf="isSkeletonLoading"
                      styleClass="mb-2"
                    ></p-skeleton>
                    <p *ngIf="!isSkeletonLoading && instructions">
                      {{ instructions.carpoolSummary }}
                    </p>
                  </div>
                  <div class="mb-2">
                    <span class="form-title">Carpool Ethics</span>
                    <p-skeleton
                      *ngIf="isSkeletonLoading"
                      styleClass="mb-2"
                    ></p-skeleton>
                    <p-skeleton
                      *ngIf="isSkeletonLoading"
                      styleClass="mb-2"
                    ></p-skeleton>
                    <p *ngIf="!isSkeletonLoading && instructions">
                      {{ instructions.carpoolEthics }}
                    </p>
                  </div>
                  <div class="mb-2">
                    <span class="form-title">Driving Instructions</span>
                    <p-skeleton
                      *ngIf="isSkeletonLoading"
                      styleClass="mb-2"
                    ></p-skeleton>
                    <p-skeleton
                      *ngIf="isSkeletonLoading"
                      styleClass="mb-2"
                    ></p-skeleton>
                    <p *ngIf="!isSkeletonLoading && instructions">
                      {{ instructions.drivingInstructions }}
                    </p>
                  </div>
                  <div class="mb-2">
                    <span class="form-title">Vehicle Snapshot</span>
                    <p-skeleton
                      *ngIf="isSkeletonLoading"
                      styleClass="mb-2"
                    ></p-skeleton>
                    <p-skeleton
                      *ngIf="isSkeletonLoading"
                      styleClass="mb-2"
                    ></p-skeleton>
                    <p *ngIf="!isSkeletonLoading && instructions">
                      {{ instructions.vehicleSnapshot }}
                    </p>
                  </div>
                  <div>
                    <span class="form-title">Huddle Snashot</span>
                    <p-skeleton
                      *ngIf="isSkeletonLoading"
                      styleClass="mb-2"
                    ></p-skeleton>
                    <p-skeleton
                      *ngIf="isSkeletonLoading"
                      styleClass="mb-2"
                    ></p-skeleton>
                    <span *ngIf="!isSkeletonLoading && instructions">{{
                      instructions.huddleSnapshot
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col md:flex-row" *ngIf="!carpool">
              <div class="flex-1">
                <div
                  class="grid cursor-pointer grid-cols-1 gap-x-2 gap-y-3 px-4 py-4 sm:grid-cols-6"
                >
                  <div class="col-span-6 md:col-span-6">
                    <h3 class="h-level-3">No upcoming carpool</h3>
                    <p>You do not have any upcoming carpool</p>
                    <div class="my-1 text-center">
                      <button
                        type="button"
                        pButton
                        (click)="onCarpool()"
                        class="mp-button mp-button-outlined mb-4 truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
                      >
                        <span>Create Carpool</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <p-divider
                layout="vertical"
                styleClass="mp-divider md:block hidden"
              ></p-divider>
              <p-divider styleClass="mp-divider block md:hidden"></p-divider>
              <div
                class="grid flex-1 cursor-pointer grid-cols-1 gap-x-2 gap-y-3 px-4 py-4 sm:grid-cols-6"
              >
                <div class="col-span-6 md:col-span-6">
                  <h3 class="h-level-3">Going to huddle?</h3>
                  <p>
                    Do you know, if you are going to Huddle, you can lead or
                    join a carpool from the huddle page to have fun drive time,
                    make new friends and save the enviroment at the time.
                  </p>
                  <div class="my-1 text-center">
                    <button
                      type="button"
                      pButton
                      (click)="
                        redirect(['/huddle', 'huddles'], 'huddle_huddles')
                      "
                      class="mp-button mp-button-outlined mb-4 truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
                    >
                      <span>Explore Huddles</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </p-card>
          <div *ngIf="carpool" class="mt-1 flex justify-end">
            <span class="h-level-3">Explore more at&nbsp;</span>
            <a class="primary h-level-3" [routerLink]="['/huddle', 'carpools']">
              Carpools
            </a>
          </div>
        </div>
      </div>
    </section>

    <section id="others" class="mb-4">
      <h2 class="h-level-2 mb-2">Other Huddles</h2>
      <div class="dashboard shrink grow basis-0">
        <app-slider [isDark]="isDark" objectType="HUDDLE"></app-slider>
      </div>
    </section>

    <section id="others-carpool" class="mb-24">
      <h2 class="h-level-2 mb-2">Other Carpools</h2>
      <div class="dashboard shrink grow basis-0 p-[4px]">
        <app-slider
          [isDark]="isDark"
          objectType="CARPOOL"
          [userInfo]="userInfo"
        ></app-slider>
      </div>
    </section>
  </div>
</div>

<p-menu
  #menu
  [model]="huddleOptions"
  [popup]="true"
  appendTo="body"
  styleClass="p-0"
>
  <ng-template pTemplate="item" let-item>
    <a
      (click)="onOverlayMenuClick(item.id)"
      class="p-menuitem-link justify-content-between align-items-center flex p-3 dark:border-[#1F242F] dark:bg-[#1F242F] dark:text-slate-300 hover:dark:bg-[#1F242F]"
    >
      <div>
        <span class="ml-2"> {{ item.label }}</span>
      </div>
    </a>
  </ng-template>
</p-menu>

<p-menu
  #menuCarpool
  [model]="carpoolOptions"
  [popup]="true"
  appendTo="body"
  styleClass="p-0"
>
  <ng-template pTemplate="item" let-item>
    <a
      *ngIf="item.id !== 'open_huddle'"
      (click)="
        redirect(['huddle', 'carpool', 'view', '' + carpool.id], item.id)
      "
      class="p-menuitem-link justify-content-between align-items-center flex p-3 dark:border-[#1F242F] dark:bg-[#1F242F] dark:text-slate-300 hover:dark:bg-[#1F242F]"
    >
      <div>
        <span class="ml-2"> {{ item.label }}</span>
      </div>
    </a>
    <a
      *ngIf="item.id === 'open_huddle' && !hasHuddle"
      (click)="redirect(['huddle', 'view', '' + carpool.parentObj.id])"
      class="p-menuitem-link justify-content-between align-items-center flex p-3 dark:border-[#1F242F] dark:bg-[#1F242F] dark:text-slate-300 hover:dark:bg-[#1F242F]"
    >
      <div>
        <span class="ml-2"> {{ item.label }}</span>
      </div>
    </a>
  </ng-template>
</p-menu>

<app-dialog
  [visibleChange]="dialogEvent.asObservable()"
  [view]="'CARPOOL'"
  header="Create"
  [userDetails]="userInfo"
  width="50vw"
></app-dialog>

<app-sharelink
  *ngIf="selectedHuddle"
  [event]="shareLinkEvent.asObservable()"
  objectType="HUDDLE"
  [objectId]="selectedHuddle.id"
></app-sharelink>

<app-sharelink
  *ngIf="carpool"
  [event]="shareLinkEventCarpool.asObservable()"
  objectType="CARPOOL"
  [objectId]="carpool.id"
></app-sharelink>

<p-confirmDialog #cd styleClass="md:w-5/12 w-10/12" appendTo="body">
  <ng-template pTemplate="header">
    <h3 class="h-level-3">Create a new Carpool</h3>
  </ng-template>
  <ng-template pTemplate="message">
    <p class="mt-2">
      Would you like to set up a carpool independently? It's recommended to
      start by creating a Huddle for your carpool group, and then create or join
      a carpool from within that Huddle.
    </p>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="mt-2 flex flex-col justify-start gap-4 xl:flex-row">
      <div class="order-2 flex-1 text-left xl:order-none">
        <button
          type="button"
          pButton
          (click)="cd.reject()"
          class="mp-button mp-button-outlined w-full justify-center rounded-md border px-5 py-2 leading-6 transition-all xl:w-auto"
        >
          Cancel
        </button>
      </div>
      <button
        pButton
        (click)="onConfirm()"
        class="mp-button mp-button-filled justify-center truncate rounded-md px-5 py-2 leading-6 shadow-sm"
      >
        Create Carpool
      </button>
      <button
        pButton
        (click)="onConfirm('/huddle/create')"
        class="mp-button mp-button-filled justify-center truncate rounded-md px-5 py-2 leading-6 shadow-sm"
      >
        Create Huddle for Carpools
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
