import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  faUser,
  faUsers,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { Observable, Subject } from 'rxjs';
import {
  initMap,
  activityMap,
  visibilityMap,
  paymentType,
} from 'src/app/main/utilities';
import {
  Huddle,
  Huddlers,
  DialogEvent,
  User,
  HuddleFeatures,
} from 'src/app/main/types/main.types';
import { environment } from 'src/environments/environment';
import { ConnectionsService } from 'src/app/main/services/connections.service';
import { HuddleService } from 'src/app/main/services/huddle.service';
import { MessageService } from 'primeng/api';
import moment from 'moment';
import { Router } from '@angular/router';
import { calculateDuration } from 'src/app/main/utilities';

@Component({
  selector: 'app-view-about',
  templateUrl: './huddle-view-about.component.html',
})
export class HuddleViewAboutComponent implements OnInit {
  userPickerVisible = false;
  faUser: IconDefinition = faUser;
  faUsers: IconDefinition = faUsers;
  coHosts: any = [];
  isLoading = true;
  rsvpedHuddlers: Huddlers[];
  totalGoing: number;
  totalNotGoing: number;
  totalMayBe: number;
  visible = false;
  huddle: Huddle;
  showHuddleDesc = false;
  showFullVenueNote = false;
  showFullMeetingNote = false;
  showFullParkingNote = false;
  descriptionLength = 0;
  totalCarpoolers: number;
  totalCarpools: number;
  totalWaiting: number;
  activityMap = activityMap;
  followings: User[] = [];
  suggestedInvites: User[] = [];
  moment = moment;
  isCarpoolAllowed: boolean | undefined = false;
  visibilityMap = visibilityMap;
  paymentMode = paymentType;
  @Input() huddleId: string | null;
  @Input() huddleView: Huddle;
  @Input() events: Observable<Huddle>;
  @Output() view = new EventEmitter();
  @Output() huddlers = new EventEmitter();
  userPickerEvent: Subject<any> = new Subject<any>();
  dialogEvent: Subject<DialogEvent> = new Subject<DialogEvent>();

  constructor(
    private messageService: MessageService,
    private huddleService: HuddleService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    //   this.events.subscribe((data: Huddle) => {
    const { venue, parking, meetingPoint, coHosts, rsvpedHuddlers, features } =
      this.huddleView;
    const addresses: { label: string; address: string | undefined }[] = [];
    venue && addresses.push({ label: 'V', address: venue.formattedAddress });
    parking &&
      addresses.push({ label: 'P', address: parking.formattedAddress });
    meetingPoint &&
      addresses.push({ label: 'M', address: meetingPoint.formattedAddress });
    !venue &&
      !meetingPoint &&
      !parking &&
      addresses.push({ label: '', address: 'New York, NY, USA' });

    initMap('gmap-about', addresses as { label: string; address: string }[]);
    this.isCarpoolAllowed = features?.some(
      (u: any) => u.enabled && u.key === 'CARPOOLING',
    );
    this.coHosts = coHosts;
    this.rsvpedHuddlers = rsvpedHuddlers;
    this.rsvpActions();
    this.huddle = this.huddleView;

    this.events.subscribe((data) => {
      this.rsvpedHuddlers = data.rsvpedHuddlers;
      this.getCarpools();
      this.getSuggestedInvites();
      this.rsvpActions();
    });
  }

  calculateDuration(startDate: string, endDate: string | undefined) {
    return calculateDuration(startDate, endDate);
  }

  getProfileImageById(id: number | undefined) {
    return environment.apiUrl + '/api/user/' + id + '/pic';
  }

  getCarpools() {
    this.huddleService
      .getCarpools({ parentEntity: 'HUDDLE', parentId: this.huddleView.id })
      .subscribe({
        next: (data: any) => {
          const { totalCarpoolers, totalCarpools, totalWaiting } = data;
          this.totalCarpoolers = totalCarpoolers;
          this.totalCarpools = totalCarpools;
          this.totalWaiting = totalWaiting;
          this.isLoading = false;
          this.view.emit(data);
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: '',
            detail: err.message,
          });
          console.error('Request to get carpools errored out.' + err.message);
        },
      });
  }

  rsvpActions() {
    this.totalGoing = this.rsvpedHuddlers.filter(
      (r: any) => r.rsvpType === 'YES',
    ).length;
    this.totalMayBe = this.rsvpedHuddlers.filter(
      (r: any) => r.rsvpType === 'MAYBE',
    ).length;
    this.totalNotGoing = this.rsvpedHuddlers.filter(
      (r: any) => r.rsvpType === 'NO',
    ).length;
  }

  getSuggestedInvites() {
    this.huddleService.getPredictsInvitee('' + this.huddle.id, 0, 5).subscribe({
      next: (data: any) => {
        this.followings = data.content;
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: '',
          detail: err.message,
        });
        console.error(
          'Request to get suggested invites errored out.' + err.message,
        );
      },
    });
  }

  onInvite(userId: number) {
    this.huddleService
      .invitePredict('' + this.huddle.id, {
        huddleId: Number(this.huddle.id),
        userId,
      })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Huddle Invite',
            detail: data.message,
          });
          this.userPickerEvent.next('loaderStop');
          this.userPickerEvent.next('filterUser_' + userId);
          this.followings = this.followings.filter((u) => u.id !== userId);
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: '',
            detail: err.message,
          });
          console.error('Request to get invite errored out.' + err.message);
        },
      });
  }

  showMore() {
    this.userPickerVisible = true;
    this.userPickerEvent.next([]);
    this.userPickerEvent.next('huddleId_' + this.huddle.id);
    this.userPickerEvent.next('onLoad');
    this.userPickerEvent.next(true);
  }

  getLogoUrl(id: string) {
    return environment.apiUrl + '/api/communities/' + id + '/logo';
  }

  responders(tab: string) {
    this.huddlers.emit(tab);
  }

  redirect(path: string[]) {
    this.router.navigate(path);
  }
}
