<aside
  class="linear !pl-0 !pr-0 transition duration-500 xl:px-6 xl:px-8"
  [ngClass]="{ relative: sidebar, 'translate-x-full': !open }"
  (transitionend)="transitionEnd($event)"
>
  <!-- <p-card styleClass="dark:text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-900 dark:border-slate-800 dark:border"> -->
  <div
    class="surface-overlay p-5 dark:border dark:border-[#1F242F] dark:bg-[#0C111D] dark:text-slate-300"
  >
    <h2 class="h-level-2">Additional Settings</h2>

    <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div class="col-span-6 md:col-span-6">
        <span for="meeting-point" class="form-title block leading-6"
          >Co-Host
        </span>
        <div class="flex items-center justify-between gap-2">
          <div class="text-sm">User</div>
          <p-button
            (click)="showDialog()"
            label="Select"
            styleClass="primary"
            [text]="true"
          ></p-button>
        </div>
      </div>
    </div>

    <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div class="col-span-6 md:col-span-6">
        <span class="form-title block leading-6">Event Fee </span>
        <div class="flex items-center gap-8">
          <div class="flex items-center justify-between gap-2">
            <div class="field-checkbox">
              <p-radioButton
                inputId="free"
                styleClass=""
                value="free"
                (ngModelChange)="onRadioChange($event)"
                [(ngModel)]="entryFee"
              ></p-radioButton>
              <label for="free" class="ml-2">Free</label>
            </div>
            <div class="field-checkbox">
              <p-radioButton
                inputId="paid"
                styleClass=""
                value="paid"
                (ngModelChange)="onRadioChange($event)"
                [(ngModel)]="entryFee"
              ></p-radioButton>
              <label for="paid" class="ml-2">Paid</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div class="col-span-6 md:col-span-6">
        <span for="meeting-point" class="form-title block leading-6"
          >RSVP Start Date and Time
        </span>
        <div class="flex items-center gap-8">
          <div class="flex items-center justify-between gap-2">
            <div class="field-checkbox">
              <p-radioButton
                inputId="current"
                styleClass=""
                value="current-date"
                (ngModelChange)="onRSVPChange($event, 'startDate')"
                [(ngModel)]="rsvpStartDate"
              ></p-radioButton>
              <label for="current" class="ml-2">Current Date</label>
            </div>
            <div class="field-checkbox">
              <p-radioButton
                inputId="custom"
                styleClass=""
                value="custom-date"
                (ngModelChange)="onRSVPChange($event, 'startDate')"
                [(ngModel)]="rsvpStartDate"
              ></p-radioButton>
              <label for="custom" class="ml-2">Custom Date</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div class="col-span-6 md:col-span-6">
        <span for="meeting-point" class="form-title block leading-6"
          >RSVP End Date and Time
        </span>
        <div class="flex items-center gap-8">
          <div class="flex items-center justify-between gap-2">
            <div class="field-checkbox">
              <p-radioButton
                inputId="rsvp-huddle-start-date"
                styleClass=""
                value="same-huddle-date"
                (ngModelChange)="onRSVPChange($event, 'endDate')"
                [(ngModel)]="rsvpEndDate"
              ></p-radioButton>
              <label for="rsvp-huddle-start-date" class="ml-2"
                >Huddle Start Date</label
              >
            </div>
            <div class="field-checkbox">
              <p-radioButton
                inputId="rsvp-custom"
                styleClass=""
                value="custom-rsvp-date"
                (ngModelChange)="onRSVPChange($event, 'endDate')"
                [(ngModel)]="rsvpEndDate"
              ></p-radioButton>
              <label for="rsvp-custom" class="ml-2">Custom Date</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div class="col-span-6 md:col-span-6">
        <span class="form-title block leading-6">Meeting Point </span>
        <div class="flex items-center justify-between gap-2">
          <div class="field-checkbox">
            <p-radioButton
              inputId="same-location"
              styleClass=""
              value="meeting-same"
              (ngModelChange)="onMeetingPointChange($event)"
              [(ngModel)]="meetingLocation"
            ></p-radioButton>
            <label for="same-location" class="ml-2">Same as Venue</label>
          </div>
          <div class="field-checkbox">
            <p-radioButton
              inputId="custom-location"
              styleClass=""
              value="meeting-custom"
              (ngModelChange)="onMeetingPointChange($event)"
              [(ngModel)]="meetingLocation"
            ></p-radioButton>
            <label for="custom-location" class="ml-2">Custom Location</label>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div class="col-span-6 md:col-span-6">
        <span for="meeting-point" class="form-title block leading-6"
          >Parking Location
        </span>
        <div class="flex items-center justify-between gap-2">
          <div class="field-checkbox">
            <p-radioButton
              inputId="parking-same-location"
              styleClass=""
              value="parking-same"
              (ngModelChange)="onParkingAddrressChange($event)"
              [(ngModel)]="parkingLocation"
            ></p-radioButton>
            <label for="parking-same-location" class="ml-2"
              >Same as Venue</label
            >
          </div>
          <div class="field-checkbox">
            <p-radioButton
              inputId="parking-custom-location"
              styleClass=""
              value="parking-custom"
              (ngModelChange)="onParkingAddrressChange($event)"
              [(ngModel)]="parkingLocation"
            ></p-radioButton>
            <label for="parking-custom-location" class="ml-2"
              >Custom Location</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <a
    *ngIf="!sidebar"
    tabindex="0"
    class="absolute right-5 top-[1.2rem] z-[100] h-[34px] w-[34px] cursor-pointer rounded-md bg-teal-500 text-center leading-[34px] shadow-lg"
    (click)="onContextMenu()"
    [ngClass]="{ '-left-[2rem]': !drawerOpened }"
  >
    <fa-icon
      [icon]="open ? faAngleRight : faAngleLeft"
      size="1x"
      [style]="{ color: '#fff' }"
    ></fa-icon>
  </a>
</aside>

<p-dialog
  header="Select User"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [breakpoints]="{ '960px': '80vw' }"
  [draggable]="false"
  [resizable]="false"
  [appendTo]="'body'"
>
  <app-user-picker (coHostsList)="onCoHostChanged($event)"></app-user-picker>
</p-dialog>
