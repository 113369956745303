<div class="flex max-w-full flex-col items-center">
  <div class="w-full">
    <div class="mx-auto mt-3 max-w-[920px] p-2" *ngIf="!isLoading">
      <form [formGroup]="settingsFormGroup" (ngSubmit)="onSubmit()">
        <div class="text-right text-base" *ngIf="!isEditMode">
          <p-button
            class="contents"
            label="Edit"
            styleClass="ml-3 mp-button hidden"
            [text]="true"
            (click)="edit()"
          ></p-button>
        </div>
        <div class="container mb-8">
          <div>
            <span class="block leading-4">Privacy and Payment</span>
            <p class="sub-title mt-1 leading-6">
              Select Privacy and choose payment method
            </p>
          </div>
          <p-card class="mx-auto block md:mx-0 md:my-auto">
            <div class="mb-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-6 md:col-span-6">
                <label for="description" class="form-title block leading-6"
                  >Privacy</label
                >
                <div class="mt-2 flex flex-col gap-2">
                  <ng-container *ngFor="let _privacy of privacyOptions">
                    <div
                      class="field-checkbox flex items-start"
                      *ngIf="communityRadioButton === _privacy.buttonFor"
                    >
                      <p-radioButton
                        [inputId]="_privacy.key"
                        [value]="_privacy.key"
                        formControlName="privacy"
                        class="mt-[2px]"
                      ></p-radioButton>
                      <div class="inline-flex flex-col">
                        <label [for]="_privacy.key" class="ml-2"
                          >{{ _privacy.name }} -
                          <span class="gray-400">{{
                            _privacy.description
                          }}</span></label
                        >
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="col-span-6 md:col-span-6">
                <label for="description" class="form-title block leading-6"
                  >Payment</label
                >
                <div class="mt-2 flex flex-col gap-2">
                  <ng-container *ngFor="let _payment of paymentforEvents">
                    <div class="field-checkbox flex items-start">
                      <p-radioButton
                        [inputId]="_payment.value"
                        [value]="_payment.value"
                        formControlName="paymentMode"
                        class="mt-[2px]"
                      ></p-radioButton>
                      <div class="inline-flex flex-col">
                        <label [for]="_payment.value" class="ml-2">{{
                          _payment.name
                        }}</label>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div
                class="col-span-6 md:col-span-6"
                *ngIf="settingsFormGroup.get('paymentMode')?.value === 'PAID'"
              >
                <label
                  for="payment_method"
                  class="form-title mb-2 block leading-6"
                  >Paymemnt Method</label
                >
                <div class="activity flex flex-1 rounded-md border shadow-sm">
                  <p-dropdown
                    class="w-full"
                    inputId="payment_method"
                    formControlName="paymentMethod"
                    [options]="paymentMethods"
                    optionLabel="name"
                    placeholder="Select Payment Method"
                  ></p-dropdown>
                </div>
              </div>
              <div
                class="col-span-6 md:col-span-3"
                *ngIf="settingsFormGroup.get('paymentMode')?.value === 'PAID'"
              >
                <label
                  for="payment_currency"
                  class="form-title mb-2 block leading-6"
                  >Currency</label
                >
                <div class="activity flex flex-1 rounded-md border shadow-sm">
                  <p-dropdown
                    class="w-full"
                    inputId="payment_currency"
                    formControlName="paymentCurrency"
                    [options]="currencies"
                    optionLabel="name"
                    placeholder="Select Currency"
                  ></p-dropdown>
                </div>
              </div>
              <div
                class="col-span-6 md:col-span-3"
                *ngIf="settingsFormGroup.get('paymentMode')?.value === 'PAID'"
              >
                <label
                  for="payment_method"
                  class="form-title mb-2 block leading-6"
                  >Amount</label
                >
                <div class="p-inputgroup basis-[40%]">
                  <p-inputNumber
                    inputId="integeronly"
                    mode="decimal"
                    [minFractionDigits]="2"
                    [maxFractionDigits]="2"
                    inputId="withoutgrouping"
                    [useGrouping]="false"
                    class="w-full rounded-l-md border"
                    styleClass="w-full h-12"
                    formControlName="paymentAmount"
                  ></p-inputNumber>
                  <span
                    *ngIf="settingsFormGroup.get('paymentCurrency')?.value"
                    class="p-inputgroup-addon"
                    >{{
                      settingsFormGroup.get("paymentCurrency")?.value.value
                    }}</span
                  >
                </div>
              </div>
              <div
                class="col-span-6 md:col-span-6"
                *ngIf="settingsFormGroup.get('paymentMode')?.value === 'PAID'"
              >
                <label for="refund_policy" class="form-title block leading-6"
                  >Refund Policy</label
                >
                <div class="mt-2">
                  <textarea
                    pInputTextarea
                    rows="5"
                    cols="30"
                    id="refund_policy"
                    formControlName="refundPolicy"
                    class="block w-full rounded-md border px-2 py-1.5"
                  ></textarea>
                </div>
                <span
                  >Attendees must be refunded if the huddle is canceled or
                  rescheduled. Read
                  <a href="#" class="primary">Payment Policies</a> for more
                  information.</span
                >
              </div>
            </div>
          </p-card>
        </div>
        <div class="container mb-8">
          <div class="mb-8">
            <div class="mb-2 text-base">
              <span class="block leading-4">Advanced Feaature</span>
            </div>
            <p-card class="mx-auto block md:mx-0 md:my-auto">
              <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="col-span-6">
                  <div class="">
                    <label class="form-title block leading-6"
                      >Carpooling Enabled?
                      <span
                        class="material-icons sub-title"
                        pTooltip="Carpooling"
                        tooltipEvent="focus"
                        tabindex="-1"
                        >info</span
                      ></label
                    >
                    <div class="mt-2">
                      <div class="field-checkbox mb-2">
                        <p-checkbox
                          [binary]="true"
                          formControlName="carpoolAllowed"
                          inputId="allow-carpool"
                          class="float-left"
                        ></p-checkbox>
                        <label for="allow-carpool" class="ms-2"
                          >Enable carpooling features for this huddle</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-card>
          </div>
        </div>
        <div class="container mb-8">
          <div class="mb-8">
            <div class="mb-2 text-base">
              <span class="block leading-4">Additional Information</span>
            </div>
            <p-card class="mx-auto block md:mx-0 md:my-auto">
              <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="col-span-6">
                  <div class="">
                    <div class="mt-2">
                      <div class="field-checkbox mb-2 flex items-start gap-2">
                        <p-checkbox
                          [binary]="true"
                          inputId="decisive"
                          class="float-left"
                          formControlName="decisiveRsvp"
                        ></p-checkbox>
                        <div class="flex flex-col">
                          <label for="decisive">Decisive RSVPing</label>
                          <span class="gray-400"
                            >If this is enabled, participants need to be
                            decisive in their choice of attend or not attend
                            this huddle. No maybe/tentative option.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-span-6">
                  <div class="">
                    <div class="mt-2">
                      <div class="field-checkbox mb-2 flex items-start gap-2">
                        <p-checkbox
                          [binary]="true"
                          inputId="approval"
                          class="float-left"
                          formControlName="approvalRequired"
                        ></p-checkbox>
                        <div class="flex flex-col">
                          <label for="approval">Require Approval</label>
                          <span class="gray-400"
                            >If this is enabled, participants will be placed in
                            waiting list and event host needs to be manually
                            approve or decline their participation
                            request.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-span-6">
                  <div class="">
                    <div class="mt-2">
                      <div class="field-checkbox mb-2 flex items-start gap-2">
                        <p-checkbox
                          [binary]="true"
                          inputId="decisive"
                          class="float-left"
                          [(ngModel)]="attendeeLimitChecked"
                          [ngModelOptions]="{ standalone: true }"
                          [disabled]="true"
                        ></p-checkbox>
                        <div class="flex flex-col">
                          <label for="decisive">Attendee Limit</label>
                          <span class="gray-400 mb-2"
                            >Limit how many participants can join the huddle”.
                            If host check this checkbox, show an inputbox to let
                            host enter the limit.</span
                          >
                          <p-inputNumber
                            *ngIf="attendeeLimitChecked"
                            class="w-full rounded-md border"
                            [ngClass]="{
                              'border-red-300':
                                submitted && f['participantLimit'].errors,
                            }"
                            styleClass="w-full h-12 rounded-md"
                            formControlName="participantLimit"
                            mode="decimal"
                            inputId="withoutgrouping"
                            [useGrouping]="false"
                          >
                          </p-inputNumber>
                          <div
                            *ngIf="submitted && f['participantLimit'].errors"
                            class="invalid-feedback text-red-500"
                          >
                            <small
                              *ngIf="
                                f['participantLimit'].errors[
                                  'lessThanCoHostAndHost'
                                ]
                              "
                              >Participant limit value cannot be less than the
                              total counts of host and co-hosts</small
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-span-6">
                  <div class="">
                    <div class="mt-2">
                      <div class="field-checkbox mb-2 flex items-start gap-2">
                        <p-checkbox
                          [binary]="true"
                          inputId="decisive"
                          class="float-left"
                          formControlName="participantVisible"
                        ></p-checkbox>
                        <div class="flex flex-col">
                          <label for="decisive">Do not show participants</label>
                          <span class="gray-400"
                            >Wheater you want to hide or show the participant
                            list to other participants. All participants will
                            always be visible to host and co-hosts.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-span-6">
                  <div class="">
                    <div class="mt-2">
                      <div class="field-checkbox mb-2 flex items-start gap-2">
                        <p-checkbox
                          [binary]="true"
                          inputId="decisive"
                          class="float-left"
                          formControlName="guestAllowed"
                        ></p-checkbox>
                        <div class="flex flex-col">
                          <label for="decisive">Allow Guests</label>
                          <span class="gray-400 mb-2"
                            >Do you want to allow participants to bring
                            guests?.</span
                          >
                          <div
                            class="mb-3"
                            *ngIf="settingsFormGroup.get('guestAllowed')?.value"
                          >
                            <label for="withoutgroupingGuestLimit" class="mb-2"
                              >Guest limit per participant</label
                            >
                            <p-inputNumber
                              class="w-full rounded-md border"
                              [ngClass]="{
                                'border-red-300':
                                  submitted && f['guestLimit'].errors,
                              }"
                              styleClass="w-full h-12"
                              formControlName="guestLimit"
                              mode="decimal"
                              inputId="withoutgroupingGuestLimit"
                              [useGrouping]="false"
                            >
                            </p-inputNumber>
                            <div
                              *ngIf="submitted && f['guestLimit'].errors"
                              class="invalid-feedback text-red-500"
                            >
                              <small
                                *ngIf="
                                  f['guestLimit'].errors['limitFiveOrEqualTo']
                                "
                                >Guest Limit more than 1 but less than for equal
                                to 5 is allowed</small
                              >
                            </div>
                          </div>
                          <div
                            class=""
                            *ngIf="settingsFormGroup.get('guestAllowed')?.value"
                          >
                            <label class="mb-3">Allowed Age Ranges</label>
                            <div
                              class="checkbox mb-2"
                              *ngFor="let ageRange of ageRanges; let i = index"
                            >
                              <p-checkbox
                                [inputId]="ageRange.value"
                                class="float-left"
                                [value]="ageRange.value"
                                [(ngModel)]="guestAllowedAges"
                                [disabled]="true"
                                [ngModelOptions]="{ standalone: true }"
                              ></p-checkbox>
                              <label [for]="ageRange.value" class="ml-2">{{
                                ageRange.name
                              }}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-card>
          </div>
        </div>
        <div class="my-6 flex justify-end gap-4" *ngIf="isEditMode">
          <button
            pButton
            type="button"
            pButton
            (click)="isEditMode = false"
            class="mp-button mp-button-outlined rounded-md px-5 py-2 leading-6 shadow-sm"
          >
            Cancel
          </button>
          <button
            pButton
            type="submit"
            pButton
            [loading]="isLoading"
            class="mp-button mp-button-filled rounded-md px-5 py-2 leading-6 shadow-sm"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
