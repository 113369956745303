import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from 'src/app/main/store/model/state.model';
import { Store } from '@ngrx/store';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Observable, Subject, tap } from 'rxjs';
import { HuddleService } from 'src/app/main/services/huddle.service';
import {
  App,
  Carpool,
  DialogEvent,
  Huddle,
  HuddleFilters,
  HuddleSmartInstructions,
  SmartInstructions,
  User,
  UserDetails,
} from 'src/app/main/types/main.types';
import { environment } from 'src/environments/environment';
import { activityMap } from 'src/app/main/utilities';
import {
  carpoolOptions,
  huddleOptions,
} from 'src/app/main/utilities/overlayHelper';
import { Menu } from 'primeng/menu';

@Component({
  selector: 'app-huddle-dashboard',
  templateUrl: './huddle-dashboard.component.html',
})
export class HuddleDashboardComponent {
  isSkeletonLoading = false;

  isDark = false;

  isLoading = true;

  carpool: Carpool;

  eventLongDate = '';

  eventLongDateEnd = '';

  hasHuddle = false;

  instructions: SmartInstructions;

  intructionsUpcoming: HuddleSmartInstructions;

  instructionsHosting: HuddleSmartInstructions;

  confirmedHuddle: Huddle;

  hostingHuddle: Huddle;

  selectedHuddle: Huddle;

  isNextHosting = false;

  isCohostingUpcoming = false;

  isCoHostingNext = false;

  profilePicUrl = '';

  activityMap = activityMap;

  userInfo: UserDetails;

  huddleOptions = huddleOptions;

  carpoolOptions = carpoolOptions;

  huddleImage: string | null = './assets/images/huddle-default-cover.svg';

  appState$: Observable<App>;

  userDetail$: Observable<UserDetails>;

  shareLinkEvent: Subject<boolean> = new Subject<boolean>();

  shareLinkEventCarpool: Subject<boolean> = new Subject<boolean>();

  dialogEvent: Subject<DialogEvent> = new Subject<DialogEvent>();

  @ViewChild('menu', { static: false }) menu: Menu;

  @ViewChild('menuCarpool', { static: false }) menuCarpoolRef: Menu;

  constructor(
    private huddleService: HuddleService,
    private messageService: MessageService,
    private store: Store<AppState>,
    private router: Router,
    private confirmationService: ConfirmationService,
  ) {
    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.userInfo = user;
          this.getCarpool(0, {
            timeline: 'UPCOMING',
            membership: 'ALL',
            tripType: 'ALL',
          });
        }),
      );

    this.userDetail$.subscribe();
    this.getAllHuddles(0, {
      startDateFrom: new Date().toISOString(),
      startDateTo: new Date().toISOString(),
      timeline: 'UPCOMING',
      activityType: null,
      participationType: 'CONFIRMED',
    }).then(() => {
      this.getAllHuddles(
        0,
        {
          startDateFrom: new Date().toISOString(),
          startDateTo: new Date().toISOString(),
          timeline: 'UPCOMING',
          activityType: null,
          participationType: 'HOSTING',
        },
        false,
      );
    });

    this.appState$ = this.store
      .select((store) => store.app)
      .pipe(
        tap((appState: App) => {
          this.isDark = appState.isDark;
        }),
      );
    this.appState$.subscribe();
  }

  getHuddleInstructions(
    id: number | undefined,
    userId: number | undefined,
    confirmed = true,
  ) {
    this.isSkeletonLoading = true;
    this.huddleService.getHuddleInstructions('' + id, userId).subscribe({
      next: (data: any) => {
        if (confirmed) {
          this.intructionsUpcoming = data;
        } else {
          this.instructionsHosting = data;
        }
        this.isSkeletonLoading = false;
      },
      error: (err) => {
        this.isSkeletonLoading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'AI Instructions',
          detail: err?.error
            ? err.error.message
            : 'Error while fetching AI instructions',
        });
      },
    });
  }

  getAllHuddles(page: number, filters: HuddleFilters, isConfirmed = true) {
    return new Promise((resolve) => {
      this.huddleService.getAllHuddles(page, 1, filters).subscribe({
        next: (data: any) => {
          if (isConfirmed && data.content[0]) {
            this.confirmedHuddle = data.content[0];
            this.isCohostingUpcoming = this.confirmedHuddle.coHosts.some(
              (host: User) => host.id === this.userInfo.id,
            );
            this.eventLongDate = this.confirmedHuddle.startDateTime;
            this.eventLongDateEnd = this.confirmedHuddle.endDateTime as string;
            this.profilePicUrl = `${environment.apiUrl}/api/user/${this.confirmedHuddle.host?.id}/pic`;
          } else if (data.content[0]) {
            this.hostingHuddle = data.content[0];
            this.isCoHostingNext = this.hostingHuddle.coHosts.some(
              (host: User) => host.id === this.userInfo.id,
            );
            this.isNextHosting =
              this.hostingHuddle.host?.id === this.userInfo.id &&
              this.hostingHuddle.id !== this.confirmedHuddle?.id;
          }
          this.isLoading = false;

          if (this.confirmedHuddle && isConfirmed) {
            this.getHuddleInstructions(
              this.confirmedHuddle.id,
              this.confirmedHuddle.selfHuddler?.id,
            );
          }

          if (this.hostingHuddle && this.isNextHosting) {
            this.getHuddleInstructions(
              this.hostingHuddle.id,
              this.hostingHuddle.selfHuddler?.id,
              !1,
            );
          }

          resolve(!0);
        },
        error: (err) => {
          this.isLoading = false;
          console.error('Request to get all huddle errored out.' + err.message);
        },
      });
    });
  }

  onOverlayMenuClick(id: string) {
    if (id === 'open_huddle') {
      this.router.navigate(['huddle', 'view', this.selectedHuddle.id]);
    } else if (id === 'share_link') {
      this.shareLinkEvent.next(!0);
    }
  }

  toggle(event: any, huddle: Huddle) {
    this.menu.toggle(event);
    this.selectedHuddle = huddle;
  }

  getHuddleCoverImage(id: number | undefined) {
    return environment.apiUrl + '/api/huddles/' + id + '/cover';
  }

  getProfileImage(id: number | undefined) {
    return environment.apiUrl + '/api/user/' + id + '/pic';
  }

  // redirect(path: Array<string>) {
  //   this.router.navigate(path);
  // }

  openMenu(e: any, carpool: Carpool) {
    this.hasHuddle = !carpool.parentObj;
    this.carpool = carpool;
    this.menuCarpoolRef.toggle(e);
  }

  openMap(location: string | undefined) {
    window.open(
      'https://www.google.com/maps/dir/?api=1&z=12&destination=' +
        encodeURIComponent(location as string),
      '_blank',
    );
  }

  redirect(path: string[], itemId: string | null = null) {
    const pathIds = [
      'open_carpool',
      'open_huddle',
      'huddle_huddles',
      'huddle_create',
    ];
    if (pathIds.includes(itemId as string)) this.router.navigate(path);
    else if (itemId === 'share_link') {
      this.shareLinkEvent.next(true);
    } else if (itemId === 'share_link_carpool') {
      this.shareLinkEventCarpool.next(true);
    }
  }

  getCarpool(page: number, data: any) {
    this.isLoading = true;
    this.huddleService.getAllCarpools(page, data, 1).subscribe({
      next: (data: any) => {
        if (data.content.length) {
          this.carpool = data.content[0];
          this.profilePicUrl = `${environment.apiUrl}/api/user/${this.carpool.leader.id}/pic`;
          this.getCarpoolInstructions();
        }
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        console.error('Request to get vehicles errored out.' + err.message);
      },
    });
  }

  getCarpoolInstructions() {
    const carpooler = this.carpool.carpoolers.find(
      (c) => c.member.id === this.userInfo.id,
    );
    this.isSkeletonLoading = true;
    this.huddleService
      .getCarpoolInstructions(this.carpool.id, carpooler?.id)
      .subscribe({
        next: (data: any) => {
          this.instructions = data;
          this.isSkeletonLoading = false;
        },
        error: (err) => {
          this.isSkeletonLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'AI Instructions',
            detail: err?.error
              ? err.error.message
              : 'Error while fetching AI instructions',
          });
        },
      });
  }

  onCarpool() {
    this.confirmationService.confirm({
      accept: () => {},
      reject: () => {},
    });
  }

  onConfirm(link?: string) {
    if (link) {
      this.router.navigate([link], {
        queryParams: { activity: 'general', features: 'carpooling' },
      });
    } else {
      this.dialogEvent.next({
        visible: true,
        dialogType: 'CARPOOL',
        isCreate: true,
        isViewMode: false,
      });
    }
    this.confirmationService.close();
  }
}
