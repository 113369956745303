<div class="surface-ground p-2" #createHuddle>
  <div #huddle></div>
  <!-- <div>{{ mobileQuery.matches }}</div> -->
  <mat-drawer-container autosize>
    <div class="mx-auto max-w-[720px]">
      <div
        class="linear max mx-auto mb-24 transition-all duration-500"
        [ngClass]="{ 'max-w-[680px]': !isContextOpen }"
      >
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
          <div class="container mb-8 mt-4">
            <h2
              class="h-level-2 mb-4"
              [ngClass]="!isContextOpen ? 'text-center' : ''"
            >
              Create a Huddle
            </h2>
            <div class="mb-8 text-base">
              <span class="block leading-4">Basic Informaton</span>
              <p class="sub-title mt-1 leading-6">
                Provide the basic information for the huddle.
              </p>
            </div>
            <p-card class="mx-auto block md:mx-0 md:my-auto lg:w-[680px]">
              <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="col-span-6 md:col-span-6">
                  <div class="relative -ml-[1.25rem] -mr-[1.25rem] -mt-[4rem]">
                    <div
                      #image
                      (mousedown)="onMouseDown($event)"
                      (mouseup)="onMouseUp()"
                      (mousemove)="onMouseMove($event)"
                      (mouseleave)="onMouseUp()"
                      class="relative flex h-[200px] w-full items-center justify-center overflow-hidden"
                    >
                      <img
                        *ngIf="croppedImage"
                        [src]="croppedImage"
                        alt=""
                        [ngStyle]="{ 'top.px': translateY, cursor: 'move' }"
                        class="absolute top-0 min-h-full min-w-full shrink-0 object-cover object-top"
                      />
                      <img
                        *ngIf="!croppedImage"
                        [src]="
                          selectedActivity.value !== 'HIKING'
                            ? '../assets/images/huddle-default-cover.svg'
                            : '../assets/images/huddle-default-cover-hiking.svg'
                        "
                        alt=""
                        class="absolute bottom-0 min-h-full min-w-full shrink-0 object-cover object-top"
                      />
                    </div>
                    <div
                      *ngIf="croppedImage && !isDraggingStart"
                      class="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4"
                    >
                      <div class="mask-bg flex gap-2 rounded-md p-3 text-white">
                        <span class="material-icons">open_with</span>
                        <span>Drag to Reposition</span>
                      </div>
                    </div>
                    <div class="flex flex-1 rounded-md">
                      <p-button
                        *ngIf="!croppedImage"
                        [outlined]="true"
                        styleClass="px-5 py-2 rounded-md absolute right-3 bottom-3 mp-button mp-button-filled hidden md:flex"
                      >
                        <span class="material-icons inline-block align-middle"
                          >add_a_photo</span
                        >
                        <label
                          for="file-upload"
                          class="relative cursor-pointer rounded-md"
                        >
                          <span class="ml-2 font-normal">Photo</span>
                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            class="sr-only"
                            (change)="fileChangeEvent($event)"
                          />
                        </label>
                      </p-button>
                      <p-button
                        *ngIf="croppedImage"
                        [outlined]="true"
                        (click)="editAndRemoveCroppedImage('D')"
                        styleClass="px-5 py-2 rounded-md absolute right-[8rem] bottom-3 mp-button mp-button-filled hidden md:flex"
                      >
                        <label
                          for="file-upload"
                          class="relative flex cursor-pointer items-center rounded-md"
                        >
                          <span class="material-icons">delete</span>
                          <span class="ml-2 hidden md:block">Remove</span>
                        </label>
                      </p-button>
                      <p-button
                        [outlined]="true"
                        (click)="huddleMenu.toggle($event)"
                        styleClass="px-5 py-2 mp-button mp-button-filled rounded-md text-sm absolute border right-[1.25rem] md:right-[7rem] bottom-3 flex md:hidden"
                      >
                        <span class="material-icons">photo_camera</span>
                        <input
                          #upload
                          id="menu-file-upload"
                          name="file-upload"
                          type="file"
                          class="sr-only"
                          (change)="fileChangeEvent($event)"
                        />
                      </p-button>
                      <p-button
                        *ngIf="croppedImage"
                        [outlined]="true"
                        styleClass="px-5 py-2 rounded-md absolute right-3 bottom-3 mp-button mp-button-filled hidden md:flex"
                      >
                        <label
                          for="file-upload-edit"
                          class="relative flex cursor-pointer items-center rounded-md"
                        >
                          <span class="material-icons">add_a_photo</span>
                          <span class="ml-2 hidden md:block">Edit</span>
                          <input
                            id="file-upload-edit"
                            name="file-upload-edit"
                            type="file"
                            class="sr-only"
                            (change)="fileChangeEvent($event)"
                          />
                        </label>
                      </p-button>
                    </div>
                    <div #huddleButtonMenu></div>
                  </div>
                </div>

                <div class="col-span-6 md:col-span-3">
                  <span for="username" class="form-title block leading-6"
                    >Event Host</span
                  >
                  <div class="flex items-center gap-2">
                    <p-avatar styleClass="mr-2" size="large" shape="circle">
                      <img
                        *ngIf="user.profilePicUrl"
                        [src]="profilePic | image | async"
                        alt=""
                      />
                      <img
                        *ngIf="!user.profilePicUrl"
                        [src]="'../assets/images/user-pic-default.svg'"
                        alt=""
                      />
                    </p-avatar>
                    <div>
                      {{ user.firstName || "" }} {{ user.lastName || "" }}
                    </div>
                  </div>
                </div>

                <div class="col-span-6 md:col-span-3">
                  <span
                    for="username"
                    class="form-title mb-1 block flex items-center"
                  >
                    <span>Co Hosts </span>
                    <p-button
                      [label]="selectedUser.length ? 'Edit' : 'Select'"
                      styleClass="primary ml-3"
                      [text]="true"
                      (click)="onInviteClick()"
                    ></p-button
                  ></span>
                  <p-scrollPanel
                    [style]="{
                      width: '100%',
                      maxHeight: '150px',
                    }"
                    styleClass="!h-auto overflow-auto thin-scrollbar"
                  >
                    <div class="selected-users rounded-md">
                      <ul role="list" class="">
                        <ng-container *ngIf="selectedUser.length === 1">
                          <li
                            *ngFor="let user of selectedUser.slice(0, 1)"
                            class="grow-1 flex-stretch ml-12 flex items-center justify-between pb-2"
                          >
                            <div class="grow-1 -ml-12 flex items-center">
                              <p-avatar
                                styleClass="mr-2"
                                size="large"
                                shape="circle"
                              >
                                <img
                                  *ngIf="user.profilePicUrl"
                                  [src]="
                                    getProfileImage(user.id) | image | async
                                  "
                                  alt="image"
                                />
                                <img
                                  *ngIf="!user.profilePicUrl"
                                  [src]="
                                    '../assets/images/user-pic-default.svg'
                                  "
                                  alt="image"
                                />
                              </p-avatar>
                              <span>{{ user.displayName }}</span>
                            </div>
                          </li>
                        </ng-container>
                        <div
                          *ngIf="selectedUser.length >= 2"
                          class="flex items-center gap-3"
                        >
                          <p-avatarGroup>
                            <p-avatar
                              *ngFor="let user of selectedUser?.slice(0, 5)"
                              size="large"
                              shape="circle"
                            >
                              <img
                                *ngIf="user.profilePicUrl"
                                [src]="getProfileImage(user.id) | image | async"
                                alt=""
                              />
                              <img
                                *ngIf="!user.profilePicUrl"
                                src="../assets/images/user-pic-default.svg"
                                alt=""
                              />
                            </p-avatar>
                            <p-avatar
                              *ngIf="selectedUser && selectedUser.length > 5"
                              [label]="
                                (selectedUser.length - 5).toString() + '+'
                              "
                              size="large"
                              shape="circle"
                            ></p-avatar>
                          </p-avatarGroup>
                          <span>co-hosting</span>
                        </div>
                        <li *ngIf="!selectedUser.length">
                          No co-host selected.
                        </li>
                      </ul>
                    </div>
                  </p-scrollPanel>
                </div>

                <div class="col-span-6">
                  <div class="mb-4 mt-2" *ngIf="!communityId">
                    <div class="field-checkbox mb-2 flex items-start">
                      <p-checkbox
                        [binary]="true"
                        [(ngModel)]="huddlePartOfCommunity"
                        inputId="part"
                        class="float-left"
                        (ngModelChange)="onHuddlePartOfCommunityChange($event)"
                        [ngModelOptions]="{ standalone: true }"
                      ></p-checkbox>
                      <div class="ml-2 flex flex-1 flex-col">
                        <label for="part"
                          >Create Huddle as part of the community</label
                        >
                        <div class="mt-2" *ngIf="huddlePartOfCommunity">
                          <div
                            class="activity flex flex-1 rounded-md border shadow-sm"
                            [ngClass]="{
                              'border-red-300':
                                submitted && f['communityId'].errors,
                            }"
                          >
                            <p-dropdown
                              class="w-full"
                              inputId="communityId"
                              formControlName="communityId"
                              [options]="communities"
                              optionLabel="name"
                              (onChange)="onCommunitySelect($event)"
                              placeholder="Select Community"
                            ></p-dropdown>
                          </div>
                          <div
                            *ngIf="submitted && f['communityId'].errors"
                            class="invalid-feedback text-red-500"
                          >
                            <small *ngIf="f['communityId'].errors['required']"
                              >Community is required</small
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p-card
                    *ngIf="community"
                    class="min-w-[320px] max-w-[600px]"
                    styleClass="shadow-none border mp-border"
                  >
                    <div class="flex flex-col">
                      <div class="flex items-center justify-between">
                        <div class="mr-3 rounded-md object-cover">
                          <a
                            class="z-0 inline-block min-h-0 w-full min-w-0 rounded-md p-0"
                            tabindex="0"
                            role="link"
                          >
                            <div class="relative inline-block align-bottom">
                              <img
                                *ngIf="!community.logoImageId"
                                alt="default"
                                class="h-[80px] w-[80px] rounded-md"
                                src="../assets/images/community-default-logo.svg"
                              />
                              <img
                                *ngIf="community.logoImageId"
                                alt="Logo"
                                class="h-[80px] w-[80px] rounded-md"
                                [src]="
                                  getLogoUrl('' + community.id) | image | async
                                "
                              />
                            </div>
                          </a>
                        </div>
                        <div class="flex flex-1">
                          <div class="flex flex-col">
                            <div class="my-1">
                              <span class="h-level-4 block min-w-0 text-left">{{
                                community.name
                              }}</span>
                            </div>
                            <span class="h-level-6">{{
                              moment(community.incorporatedAt).fromNow()
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="mt-4 flex items-center justify-between">
                        <div class="">
                          <button
                            type="button"
                            pButton
                            (click)="
                              redirect([
                                '/community',
                                'view',
                                '' + community.id,
                              ])
                            "
                            class="mp-button mp-button-filled truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
                          >
                            <span>View Community</span>
                          </button>
                        </div>
                        <div>
                          <button
                            pButton
                            type="button"
                            class="mp-button mp-button-outlined truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
                          >
                            <span class="material-icons prevent">
                              more_horiz
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </p-card>
                </div>

                <div class="col-span-6 md:col-span-6">
                  <label for="activity" class="form-title mb-1 block leading-6"
                    >Activity</label
                  >
                  <span class="text-sm"
                    >Select a activity of enable activity sepecific
                    features</span
                  >
                  <div class="mt-2">
                    <div
                      class="activity flex flex-1 rounded-md border shadow-sm"
                    >
                      <p-dropdown
                        class="w-full"
                        inputId="activity"
                        formControlName="selectedActivity"
                        [options]="activity"
                        optionLabel="name"
                        (onChange)="onAcitivitySelect($event)"
                        placeholder="Select Activity"
                      ></p-dropdown>
                    </div>
                  </div>
                </div>

                <div class="col-span-6 md:col-span-6">
                  <label for="eventName" class="form-title block leading-6"
                    >Name <span class="text-red-400">*</span></label
                  >
                  <div class="mt-2">
                    <input
                      pInputText
                      formControlName="eventName"
                      type="text"
                      id="eventName"
                      [ngClass]="{
                        'border-red-300': submitted && f['eventName'].errors,
                      }"
                      class="block h-12 w-full rounded-md border px-2 py-1.5"
                    />
                    <div
                      *ngIf="submitted && f['eventName'].errors"
                      class="invalid-feedback text-red-500"
                    >
                      <small *ngIf="f['eventName'].errors['required']"
                        >Name is required</small
                      >
                    </div>
                  </div>
                </div>

                <div class="col-span-6 md:col-span-6">
                  <span class="form-title block leading-6">Description</span>
                  <div class="mt-2 flex items-center gap-x-3">
                    <p-editor
                      #editor
                      class="flex-1"
                      (onInit)="onEditorInit($event)"
                      formControlName="eventDescription"
                      [style]="{ height: '320px' }"
                    >
                      <ng-template pTemplate="header">
                        <span class="ql-formats">
                          <button
                            type="button"
                            class="ql-bold"
                            aria-label="Bold"
                          ></button>
                          <button
                            type="button"
                            class="ql-italic"
                            aria-label="Italic"
                          ></button>
                          <button
                            type="button"
                            class="ql-underline"
                            aria-label="Underline"
                          ></button>
                        </span>
                        <span class="ql-formats">
                          <button
                            type="button"
                            class="ql-list"
                            value="ordered"
                            aria-label="Ordered List"
                          ></button>
                          <button
                            type="button"
                            class="ql-list"
                            value="bullet"
                            aria-label="Bullet List"
                          ></button>
                        </span>
                        <span class="ql-formats">
                          <select
                            class="ql-align"
                            aria-label="Text Alignment"
                          ></select>
                        </span>
                        <span class="ql-formats">
                          <button
                            type="button"
                            class="ql-link"
                            aria-label="Link"
                          ></button>
                          <button
                            type="button"
                            class="ql-video"
                            aria-label="Video"
                          ></button>
                        </span>
                      </ng-template>
                    </p-editor>
                  </div>
                </div>

                <div class="col-span-6 md:col-span-6">
                  <label for="eventName" class="form-title mb-2 block leading-6"
                    >Start Time</label
                  >
                  {{
                    f["startDate"].errors &&
                      f["startDate"].errors["startDateRequiredForEndDate"]
                  }}
                  <app-datepicker
                    [showTime]="true"
                    [event]="startDatePickerEvent.asObservable()"
                    datepickerLabel="false"
                    timerLabel="false"
                    (selectTime)="onTimeSelect($event, 'S')"
                    (selectDate)="onDateSelect($event, 'S')"
                    [isInvalid]="submitted && f['startDate'].errors"
                    [showValidationMessage]="submitted && f['startDate'].errors"
                    [showRequired]="
                      f['startDate'].errors &&
                      f['startDate'].errors['startDateRequiredForEndDate']
                    "
                    [showRequiredMessage]="
                      f['startDate'].errors &&
                      f['startDate'].errors['startDateRequiredForEndDate']
                    "
                    [validationMessage]="
                      f['startDate'].errors &&
                      f['startDate'].errors['pastStartDate']
                        ? 'Start Date and Time cannot be past'
                        : f['startDate'].errors &&
                            f['startDate'].errors['startDateRequiredForEndDate']
                          ? 'Start Date is required'
                          : ''
                    "
                  ></app-datepicker>
                </div>

                <div
                  class="col-span-6 md:col-span-6"
                  *ngIf="selectEndDateTime.value !== '-1'"
                >
                  <label
                    for="enddaterange"
                    class="form-title mb-2 block leading-6"
                    >Duration</label
                  >
                  <div
                    class="activity flex flex-1 rounded-md border shadow-sm"
                    [ngClass]="{
                      'border-red-300': submitted && f['communityId'].errors,
                    }"
                  >
                    <p-dropdown
                      class="w-full"
                      inputId="enddaterange"
                      [(ngModel)]="selectEndDateTime"
                      [ngModelOptions]="{ standalone: true }"
                      [options]="endDateTimes"
                      optionLabel="name"
                      (onChange)="onEndDateTimes($event)"
                    ></p-dropdown>
                  </div>
                </div>

                <div
                  class="col-span-6 md:col-span-6"
                  *ngIf="selectEndDateTime.value === '-1'"
                >
                  <label for="eventName" class="form-title mb-2 block leading-6"
                    >End Time</label
                  >
                  <app-datepicker
                    [showTime]="true"
                    [event]="endDatePickerEvent.asObservable()"
                    datepickerLabel="false"
                    timerLabel="false"
                    (selectTime)="onTimeSelect($event, 'R')"
                    (selectDate)="onDateSelect($event, 'R')"
                    [isInvalid]="submitted && f['endDate'].errors"
                    [showValidationMessage]="submitted && f['endDate'].errors"
                    [validationMessage]="
                      f['endDate'].errors &&
                      f['endDate'].errors['smallerThanStartDate']
                        ? 'End Date and Time should be greater than Start Date and Time'
                        : ''
                    "
                  ></app-datepicker>
                </div>
              </div>
            </p-card>
          </div>

          <div
            class="container mb-8"
            *ngIf="!['CARPOOLING'].includes(selectedActivity.value)"
          >
            <div>
              <span class="block leading-4">Venue of the Huddle</span>
              <p class="sub-title mt-1 leading-6">
                Select or enter the address of Venue of the Huddle.
              </p>
            </div>
            <p-card class="mx-auto block md:mx-0 md:my-auto lg:w-[680px]">
              <div class="mb-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="col-span-6 md:col-span-6">
                  <app-address
                    id="venue"
                    label="Venue Address"
                    (renderAddress)="renderAddress()"
                    [events]="venueAddressSubject.asObservable()"
                    (changeLocationEvent)="onChangeLocation(location.VENUE)"
                    (addressChange)="onAddressChange($event, location.VENUE)"
                  ></app-address>
                </div>

                <div class="col-span-6 md:col-span-6">
                  <label for="venueNote" class="form-title block leading-6"
                    >Venue Notes</label
                  >
                  <div class="mt-2">
                    <textarea
                      pInputTextarea
                      rows="5"
                      cols="30"
                      id="venueNote"
                      formControlName="venueNote"
                      class="block w-full rounded-md border px-2 py-1.5"
                    ></textarea>
                  </div>
                </div>
              </div>
            </p-card>
          </div>

          <div class="container mb-8">
            <div>
              <span class="block leading-4">Privacy and Payment</span>
              <p class="sub-title mt-1 leading-6">
                Select Privacy and choose payment method
              </p>
            </div>
            <p-card class="mx-auto block md:mx-0 md:my-auto lg:w-[680px]">
              <div class="mb-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="col-span-6 md:col-span-6">
                  <label for="description" class="form-title block leading-6"
                    >Privacy</label
                  >
                  <div class="mt-2 flex flex-col gap-2">
                    <ng-container *ngFor="let _privacy of privacyOptions">
                      <div
                        class="field-checkbox flex items-start"
                        *ngIf="showRadioButton === _privacy.buttonFor"
                      >
                        <p-radioButton
                          [inputId]="_privacy.key"
                          [value]="_privacy.key"
                          formControlName="privacy"
                          class="mt-[2px]"
                        ></p-radioButton>
                        <div class="inline-flex flex-col">
                          <label [for]="_privacy.key" class="ml-2"
                            >{{ _privacy.name }} -
                            <span class="gray-400">{{
                              _privacy.description
                            }}</span></label
                          >
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="col-span-6 md:col-span-6">
                  <label for="description" class="form-title block leading-6"
                    >Payment</label
                  >
                  <div class="mt-2 flex flex-col gap-2">
                    <ng-container *ngFor="let _payment of paymentforEvents">
                      <div class="field-checkbox flex items-start">
                        <p-radioButton
                          [inputId]="_payment.value"
                          [value]="_payment.value"
                          formControlName="paymentMode"
                          class="mt-[2px]"
                        ></p-radioButton>
                        <div class="inline-flex flex-col">
                          <label [for]="_payment.value" class="ml-2">{{
                            _payment.name
                          }}</label>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div
                  class="col-span-6 md:col-span-6"
                  *ngIf="formGroup.get('paymentMode')?.value === 'PAID'"
                >
                  <label
                    for="payment_method"
                    class="form-title mb-2 block leading-6"
                    >Paymemnt Method</label
                  >
                  <div class="activity flex flex-1 rounded-md border shadow-sm">
                    <p-dropdown
                      class="w-full"
                      inputId="payment_method"
                      formControlName="paymentMethod"
                      [options]="paymentMethods"
                      optionLabel="name"
                      placeholder="Select Payment Method"
                    ></p-dropdown>
                  </div>
                </div>
                <div
                  class="col-span-6 md:col-span-3"
                  *ngIf="formGroup.get('paymentMode')?.value === 'PAID'"
                >
                  <label
                    for="payment_currency"
                    class="form-title mb-2 block leading-6"
                    >Currency</label
                  >
                  <div class="activity flex flex-1 rounded-md border shadow-sm">
                    <p-dropdown
                      class="w-full"
                      inputId="payment_currency"
                      formControlName="paymentCurrency"
                      [options]="currencies"
                      optionLabel="name"
                      placeholder="Select Currency"
                    ></p-dropdown>
                  </div>
                </div>
                <div
                  class="col-span-6 md:col-span-3"
                  *ngIf="formGroup.get('paymentMode')?.value === 'PAID'"
                >
                  <label
                    for="payment_method"
                    class="form-title mb-2 block leading-6"
                    >Amount</label
                  >
                  <div class="p-inputgroup basis-[40%]">
                    <p-inputNumber
                      inputId="integeronly"
                      mode="decimal"
                      [minFractionDigits]="2"
                      [maxFractionDigits]="2"
                      inputId="withoutgrouping"
                      [useGrouping]="false"
                      class="w-full rounded-l-md border"
                      styleClass="w-full h-12"
                      formControlName="paymentAmount"
                      [ngClass]="{
                        'border-red-300':
                          submitted && f['paymentAmount'].errors,
                      }"
                    ></p-inputNumber>
                    <span
                      *ngIf="formGroup.get('paymentCurrency')?.value"
                      class="p-inputgroup-addon"
                      >{{ formGroup.get("paymentCurrency")?.value.value }}</span
                    >
                  </div>
                  <div
                    *ngIf="submitted && f['paymentAmount'].errors"
                    class="invalid-feedback text-red-500"
                  >
                    <small
                      *ngIf="
                        f['paymentAmount'].errors['paymentGreaterThanZero']
                      "
                      >Payment Amount must be greater than zero</small
                    >
                  </div>
                </div>
                <div
                  class="col-span-6 md:col-span-6"
                  *ngIf="formGroup.get('paymentMode')?.value === 'PAID'"
                >
                  <label for="refund_policy" class="form-title block leading-6"
                    >Refund Policy</label
                  >
                  <div class="mt-2">
                    <textarea
                      pInputTextarea
                      rows="5"
                      cols="30"
                      id="refund_policy"
                      formControlName="refundPolicy"
                      class="block w-full rounded-md border px-2 py-1.5"
                    ></textarea>
                  </div>
                  <span
                    >Attendees must be refunded if the huddle is canceled or
                    rescheduled. Read
                    <a href="#" class="primary">Payment Policies</a> for more
                    information.</span
                  >
                </div>
              </div>
            </p-card>
          </div>

          <div class="container mb-4">
            <div class="mr-0 flex items-center justify-between md:mr-10">
              <span class="h-level-3 block leading-4">Advance Features</span>
              <p-inputSwitch
                [(ngModel)]="advancedFeatureChecked"
                [ngModelOptions]="{ standalone: true }"
              ></p-inputSwitch>
            </div>
          </div>

          <div class="container mb-8" *ngIf="advancedFeatureChecked">
            <div
              class="mb-8"
              *ngIf="['HIKING', 'GENERIC'].includes(selectedActivity.value)"
            >
              <p-card class="mx-auto block md:mx-0 md:my-auto lg:w-[680px]">
                <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div class="col-span-6">
                    <div class="">
                      <label class="form-title block leading-6"
                        >Carpooling Enabled?
                        <span
                          class="material-icons sub-title"
                          pTooltip="Carpooling"
                          tooltipEvent="focus"
                          tabindex="-1"
                          >info</span
                        ></label
                      >
                      <div class="mt-2">
                        <div class="field-checkbox mb-2">
                          <p-checkbox
                            [binary]="true"
                            formControlName="carpoolAllowed"
                            inputId="allow-carpool"
                            class="float-left"
                          ></p-checkbox>
                          <label for="allow-carpool" class="ms-2"
                            >Enable carpooling features for this huddle</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-card>
            </div>
          </div>

          <div class="container mb-4">
            <div class="mr-0 flex items-center justify-between md:mr-10">
              <span class="h-level-3 block leading-4">Advance Settings</span>
              <p-inputSwitch
                [(ngModel)]="checked"
                [ngModelOptions]="{ standalone: true }"
              ></p-inputSwitch>
            </div>
          </div>

          <div class="container mb-8" *ngIf="checked">
            <div
              class="mb-8"
              *ngIf="['HIKING', 'GENERIC'].includes(selectedActivity.value)"
            >
              <div class="text-base">
                <span class="block leading-4">Additional Information</span>
                <p class="mt-1 text-sm leading-6">
                  Provide the additional information for the huddle.
                </p>
              </div>
              <p-card class="mx-auto block md:mx-0 md:my-auto lg:w-[680px]">
                <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div class="col-span-6">
                    <div class="">
                      <div class="mt-2">
                        <div class="field-checkbox mb-2 flex items-start gap-2">
                          <p-checkbox
                            [binary]="true"
                            inputId="decisive"
                            class="float-left"
                            formControlName="decisiveRsvp"
                          ></p-checkbox>
                          <div class="flex flex-col">
                            <label for="decisive">Decisive RSVPing</label>
                            <span class="gray-400"
                              >If this is enabled, participants need to be
                              decisive in their choice of attend or not attend
                              this huddle. No maybe/tentative option.</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-span-6">
                    <div class="">
                      <div class="mt-2">
                        <div class="field-checkbox mb-2 flex items-start gap-2">
                          <p-checkbox
                            [binary]="true"
                            inputId="approval"
                            class="float-left"
                            formControlName="approvalRequired"
                          ></p-checkbox>
                          <div class="flex flex-col">
                            <label for="approval">Require Approval</label>
                            <span class="gray-400"
                              >If this is enabled, participants will be placed
                              in waiting list and event host needs to be
                              manually approve or decline their participation
                              request.</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-span-6">
                    <div class="">
                      <div class="mt-2">
                        <div class="field-checkbox mb-2 flex items-start gap-2">
                          <p-checkbox
                            [binary]="true"
                            inputId="decisive"
                            class="float-left"
                            [(ngModel)]="attendeeLimitChecked"
                            [ngModelOptions]="{ standalone: true }"
                          ></p-checkbox>
                          <div class="flex flex-col">
                            <label for="decisive">Attendee Limit</label>
                            <span class="gray-400 mb-2"
                              >Limit how many participants can join the huddle”.
                              If host check this checkbox, show an inputbox to
                              let host enter the limit.</span
                            >
                            <p-inputNumber
                              *ngIf="attendeeLimitChecked"
                              class="w-full rounded-md border"
                              [ngClass]="{
                                'border-red-300':
                                  submitted && f['participantLimit'].errors,
                              }"
                              styleClass="w-full h-12 rounded-md"
                              formControlName="participantLimit"
                              mode="decimal"
                              inputId="withoutgrouping"
                              [useGrouping]="false"
                            >
                            </p-inputNumber>
                            <div
                              *ngIf="submitted && f['participantLimit'].errors"
                              class="invalid-feedback text-red-500"
                            >
                              <small
                                *ngIf="
                                  f['participantLimit'].errors[
                                    'lessThanCoHostAndHost'
                                  ]
                                "
                                >Participant limit value cannot be less than the
                                total counts of host and co-hosts</small
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-span-6">
                    <div class="">
                      <div class="mt-2">
                        <div class="field-checkbox mb-2 flex items-start gap-2">
                          <p-checkbox
                            [binary]="true"
                            inputId="decisive"
                            class="float-left"
                            formControlName="participantVisible"
                          ></p-checkbox>
                          <div class="flex flex-col">
                            <label for="decisive"
                              >Do not show participants</label
                            >
                            <span class="gray-400"
                              >Wheater you want to hide or show the participant
                              list to other participants. All participants will
                              always be visible to host and co-hosts.</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-span-6">
                    <div class="">
                      <div class="mt-2">
                        <div class="field-checkbox mb-2 flex items-start gap-2">
                          <p-checkbox
                            [binary]="true"
                            inputId="decisive"
                            class="float-left"
                            formControlName="guestAllowed"
                          ></p-checkbox>
                          <div class="flex flex-col">
                            <label for="decisive">Allow Guests</label>
                            <span class="gray-400 mb-2"
                              >Do you want to allow participants to bring
                              guests?.</span
                            >
                            <div
                              class="mb-3"
                              *ngIf="formGroup.get('guestAllowed')?.value"
                            >
                              <label
                                for="withoutgroupingGuestLimit"
                                class="mb-2"
                                >Guest limit per participant</label
                              >
                              <p-inputNumber
                                class="w-full rounded-md border"
                                [ngClass]="{
                                  'border-red-300':
                                    submitted && f['guestLimit'].errors,
                                }"
                                styleClass="w-full h-12"
                                formControlName="guestLimit"
                                mode="decimal"
                                inputId="withoutgroupingGuestLimit"
                                [useGrouping]="false"
                              >
                              </p-inputNumber>
                              <div
                                *ngIf="submitted && f['guestLimit'].errors"
                                class="invalid-feedback text-red-500"
                              >
                                <small
                                  *ngIf="
                                    f['guestLimit'].errors['limitFiveOrEqualTo']
                                  "
                                  >Guest Limit more than 1 but less than for
                                  equal to 5 is allowed</small
                                >
                              </div>
                            </div>
                            <div
                              class=""
                              *ngIf="formGroup.get('guestAllowed')?.value"
                            >
                              <label class="mb-3">Allowed Age Ranges</label>
                              <div
                                class="checkbox mb-2"
                                *ngFor="
                                  let ageRange of ageRanges;
                                  let i = index
                                "
                              >
                                <p-checkbox
                                  [inputId]="ageRange.value"
                                  class="float-left"
                                  [value]="ageRange.value"
                                  [(ngModel)]="guestAllowedAges"
                                  [ngModelOptions]="{ standalone: true }"
                                ></p-checkbox>
                                <label [for]="ageRange.value" class="ml-2">{{
                                  ageRange.name
                                }}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-card>
            </div>

            <div
              class="container mb-8"
              *ngIf="
                ['HIKING'].includes(selectedActivity.value) &&
                formGroup.get('paymentMode')?.value !== 'TICKETED'
              "
            >
              <div class="flex justify-between">
                <div>
                  <span class="block leading-4">RSVP Start and End Time</span>
                  <p class="sub-title mt-1 leading-6">
                    RSVP Start DaTe and Time
                  </p>
                </div>
                <div class="mr-0 flex items-center justify-between md:mr-10">
                  <p-inputSwitch
                    [(ngModel)]="rsvpChecked"
                    [ngModelOptions]="{ standalone: true }"
                    styleClass="mp-checkbox-small"
                  ></p-inputSwitch>
                </div>
              </div>
              <p-card
                class="mx-auto block md:mx-0 md:my-auto lg:w-[680px]"
                *ngIf="rsvpChecked"
              >
                <div
                  class="mb-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
                >
                  <div class="col-span-6 md:col-span-6">
                    <div class="col-span-6 md:col-span-6">
                      <label
                        for="eventName"
                        class="form-title mb-2 block leading-6"
                        >RSVP Start Time</label
                      >
                      <app-datepicker
                        [showTime]="true"
                        datepickerLabel="false"
                        timerLabel="false"
                        (selectTime)="onTimeSelect($event, 'RS')"
                        (selectDate)="onDateSelect($event, 'RS')"
                        [showToolTip]="true"
                        toolTipText="RSVP Start Date"
                      ></app-datepicker>
                    </div>
                  </div>

                  <div class="col-span-6 md:col-span-6">
                    <label
                      for="eventName"
                      class="form-title mb-2 block leading-6"
                      >RSVP End Time</label
                    >
                    <app-datepicker
                      [showTime]="true"
                      datepickerLabel="false"
                      timerLabel="false"
                      (selectTime)="onTimeSelect($event, 'RE')"
                      (selectDate)="onDateSelect($event, 'RE')"
                      [showToolTip]="true"
                      toolTipText="RSVP End Date"
                    ></app-datepicker>
                  </div>
                </div>
              </p-card>
            </div>

            <div
              class="container mb-8"
              *ngIf="['HIKING'].includes(selectedActivity.value)"
            >
              <div class="flex justify-between">
                <div class="">
                  <span class="block leading-4"
                    >Meeting Point of the Huddle</span
                  >
                  <p class="sub-title mt-1 leading-6">
                    Select or enter the address of Meeting Point of the Huddle.
                  </p>
                </div>
                <div class="mr-0 flex items-center justify-between md:mr-10">
                  <p-inputSwitch
                    [(ngModel)]="meetingPointChecked"
                    [ngModelOptions]="{ standalone: true }"
                    styleClass="mp-checkbox-small"
                  ></p-inputSwitch>
                </div>
              </div>
              <p-card
                class="mx-auto block md:mx-0 md:my-auto lg:w-[680px]"
                *ngIf="meetingPointChecked"
              >
                <div
                  class="mb-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
                >
                  <div
                    class="col-span-6 -mt-[16px] md:col-span-6"
                    [ngClass]="{ hidden: showLocation[location.MEETING] }"
                  >
                    <app-address
                      *ngIf="checked"
                      id="meeting"
                      label="Meeting Point Address"
                      (renderAddress)="renderAddress()"
                      [events]="meetingPointAddressSubject.asObservable()"
                      (changeLocationEvent)="onChangeLocation(location.MEETING)"
                      (addressChange)="
                        onAddressChange($event, location.MEETING)
                      "
                    ></app-address>
                  </div>

                  <div class="col-span-6 md:col-span-6">
                    <label
                      for="meeting_point_note"
                      class="form-title block leading-6"
                      >Meeting Point Notes</label
                    >
                    <div class="mt-2">
                      <textarea
                        pInputTextarea
                        rows="5"
                        cols="30"
                        id="meeting_point_note"
                        formControlName="meetingPointNote"
                        class="block w-full rounded-md border px-2 py-1.5"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </p-card>
            </div>

            <div
              class="container mb-8"
              *ngIf="['CARPOOLING', 'HIKING'].includes(selectedActivity.value)"
            >
              <div class="flex justify-between">
                <div>
                  <span class="block leading-4"
                    >Parking Location of the Huddle</span
                  >
                  <p class="sub-title mt-1 leading-6">
                    Select or enter the address of Parking Location of the
                    Huddle.
                  </p>
                </div>
                <div class="mr-0 flex items-center justify-between md:mr-10">
                  <p-inputSwitch
                    [(ngModel)]="parkingPointChecked"
                    [ngModelOptions]="{ standalone: true }"
                    styleClass="mp-checkbox-small"
                  ></p-inputSwitch>
                </div>
              </div>
              <p-card
                class="mx-auto block md:mx-0 md:my-auto lg:w-[680px]"
                *ngIf="parkingPointChecked"
              >
                <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div
                    class="col-span-6 -mt-[16px] md:col-span-6"
                    [ngClass]="{ hidden: showLocation[location.PARKING] }"
                  >
                    <app-address
                      *ngIf="checked"
                      label="Parking Location Address"
                      id="parking"
                      (renderAddress)="renderAddress()"
                      [events]="parkingAddressSubject.asObservable()"
                      (changeLocationEvent)="onChangeLocation(location.PARKING)"
                      (addressChange)="
                        onAddressChange($event, location.PARKING)
                      "
                    ></app-address>
                  </div>
                  <div class="col-span-6 md:col-span-6">
                    <label for="parking_note" class="form-title block leading-6"
                      >Parking Location Notes</label
                    >
                    <div class="mt-2">
                      <textarea
                        pInputTextarea
                        rows="5"
                        cols="30"
                        id="parking_note"
                        formControlName="parkingNote"
                        class="block w-full rounded-md border px-2 py-1.5"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </p-card>
            </div>

            <div class="container mb-8">
              <div class="flex justify-between">
                <div>
                  <span class="block leading-4">External URLs</span>
                  <p class="sub-title mt-1 leading-6">
                    Enter external URLs if any.
                  </p>
                </div>
                <div class="mr-0 flex items-center justify-between md:mr-10">
                  <p-inputSwitch
                    [(ngModel)]="externalUrlChecked"
                    [ngModelOptions]="{ standalone: true }"
                    styleClass="mp-checkbox-small"
                  ></p-inputSwitch>
                </div>
              </div>
              <p-card
                class="mx-auto block md:mx-0 md:my-auto lg:w-[680px]"
                *ngIf="externalUrlChecked"
              >
                <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div class="col-span-6 mb-3 md:col-span-6">
                    <label
                      for="trail_url"
                      class="form-title mb-1 block leading-6"
                      >Trail URL</label
                    >
                    <input
                      pInputText
                      type="text"
                      id="trail_url"
                      formControlName="externalTrailUrl"
                      placeholder="https://www.alltrails.com/trail/us/california/mission-peak-loop-from-stanford-avenue-staging-area"
                      class="block h-12 w-full rounded-md border px-2 py-1.5"
                    />
                  </div>
                  <div class="col-span-6 mb-3 md:col-span-6">
                    <label
                      for="event_url"
                      class="form-title mb-1 block leading-6"
                      >Event URL</label
                    >
                    <input
                      pInputText
                      type="text"
                      id="event_url"
                      formControlName="externalEventUrl"
                      placeholder="https://www.facebook.com/events/391199166920573/391199173587239"
                      class="block h-12 w-full rounded-md border px-2 py-1.5"
                    />
                  </div>
                  <div class="col-span-6 mb-3 md:col-span-6">
                    <label
                      for="album_url"
                      class="form-title mb-1 block leading-6"
                      >Photo Album URL</label
                    >
                    <input
                      pInputText
                      id="album_url"
                      type="text"
                      formControlName="externalPhotoAlbumUrl"
                      placeholder="https://photos.app.goo.gl/xd6eTFFH8Kb5mxfM6"
                      class="block h-12 w-full rounded-md border px-2 py-1.5"
                    />
                  </div>
                  <div class="col-span-6 md:col-span-6">
                    <label
                      for="comm_channel"
                      class="form-title mb-1 block leading-6"
                      >Communication Channel URL</label
                    >
                    <input
                      pInputText
                      type="text"
                      id="comm_channel"
                      formControlName="externalCommChannelUrl"
                      placeholder="https://chat.whatsapp.com/H86M3sqR6FU50v0ADIclFL"
                      class="block h-12 w-full rounded-md border px-2 py-1.5"
                    />
                  </div>
                </div>
              </p-card>
            </div>
          </div>

          <div class="my-6 flex justify-between gap-4 lg:w-[680px]">
            <button
              type="submit"
              pButton
              (click)="back()"
              class="mp-button mp-button-outlined rounded-md px-5 py-2 leading-6 shadow-sm"
            >
              Cancel
            </button>
            <div class="">
              <button
                type="submit"
                pButton
                (click)="reset()"
                class="mp-button mp-button-outlined mr-2 rounded-md px-5 py-2 leading-6 shadow-sm"
              >
                Reset
              </button>
              <button
                type="submit"
                pButton
                class="mp-button mp-button-filled rounded-md px-5 py-2 leading-6 shadow-sm"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-drawer-container>
</div>

<p-dialog
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [breakpoints]="{ '960px': '95vw' }"
  [appendTo]="'body'"
  (onShow)="onCropperDialogShow()"
  styleClass="dark:text-slate-300 dark:bg-[#0C111D] dark:border dark:border-[#1F242F]"
>
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">Crop Image</span>
  </ng-template>
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="ratio"
    (imageCropped)="imageCropped($event)"
    [transform]="transform"
    format="jpeg"
  ></image-cropper>
  <div class="overlay" *ngIf="isLoading">
    <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
  </div>
  <ng-template pTemplate="footer">
    <div
      class="flex flex-row-reverse items-center justify-between gap-3 pt-[1.25rem]"
    >
      <div>
        <p-button
          (click)="onCropperClose()"
          label="Cancel"
          styleClass="leading-6 px-5 py-2 rounded-md transition-all mp-button mp-button-outlined mr-2"
        ></p-button>
        <p-button
          (click)="onCropped()"
          label="Crop"
          styleClass="rounded-md px-5 py-2 shadow-sm mp-button mp-button-filled leading-6"
        ></p-button>
      </div>
      <div class="flex gap-2">
        <span
          class="material-icons primary"
          [ngClass]="{
            'pointer-events-none opacity-60': scale > 1.4,
            'cursor-pointer': scale < 1.4,
          }"
          (click)="zoomInAndOut('in')"
          >zoom_in</span
        >
        <span
          class="material-icons primary"
          [ngClass]="{
            'pointer-events-none opacity-60': scale === 1,
            'cursor-pointer': scale > 1,
          }"
          (click)="zoomInAndOut('out')"
          >zoom_out</span
        >
      </div>
    </div>
  </ng-template>
</p-dialog>

<!--  User Picker Component in dialog -->
<p-dialog
  header="Select User"
  [(visible)]="isUserPickerVisible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [breakpoints]="{ '960px': '80vw' }"
  [draggable]="false"
  [resizable]="false"
  [appendTo]="'body'"
>
  <app-user-picker (coHostsList)="onCoHostChange($event)"></app-user-picker>
</p-dialog>

<p-tieredMenu
  #huddleMenu
  [model]="cropperMenuOptions"
  [popup]="true"
  [appendTo]="huddleButtonMenu"
  styleClass="p-0 !left-[160px] !top-[198.8px]"
>
  <ng-template pTemplate="p-menuitem" let-obj>
    <div
      (click)="onTieredMenuItemClick(obj.action)"
      [ngClass]="
        obj.id === 'image_delete' && !croppedImage
          ? 'pointer-events-none opacity-40'
          : ''
      "
      class="flex cursor-pointer px-2 py-3 dark:bg-[#1F242F] dark:text-slate-300"
    >
      <span class="material-icons-outlined mr-1">{{ obj.icon }}</span>
      <div class="font-normal">
        <a>{{ obj.label }}</a>
      </div>
    </div>
  </ng-template>
</p-tieredMenu>

<p-dialog
  header="Select User"
  [(visible)]="userPickerVisible"
  [modal]="true"
  [style]="{ width: '30vw' }"
  [breakpoints]="{ '960px': '80vw' }"
  [draggable]="false"
  [resizable]="false"
  [appendTo]="'body'"
  styleClass="huddle-create2 p-lrb-clear"
>
  <div #inviteUser class="relative mb-2 overflow-hidden transition-[height]">
    <app-user-picker-new
      [ref]="inviteUser"
      [showSave]="true"
      [buttonText]="'Save'"
      (list)="onSelectedUser($event)"
      (remove)="onRemoveUser($event)"
      (submitUserPicker)="onSaveUser()"
      [events]="userPickerEvent.asObservable()"
    ></app-user-picker-new>
  </div>
</p-dialog>

<app-loading *ngIf="isLoading"></app-loading>
