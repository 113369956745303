<div class="flex max-w-full flex-col items-center">
  <div class="w-full">
    <div class="mx-auto max-w-[920px] p-2">
      <div class="flex" *ngIf="isSkeletonLoading">
        <p-skeleton
          width="10rem"
          height="4rem"
          styleClass="rounded-none"
        ></p-skeleton>
        <p-skeleton
          width="10rem"
          height="4rem"
          styleClass="rounded-none"
        ></p-skeleton>
      </div>
      <div
        class="grow-1 mb-2 mt-2 flex gap-2"
        *ngIf="summary && !isSkeletonLoading"
      >
        <p-selectButton
          class="hidden md:block"
          [options]="selectButtonOptions"
          (onChange)="onSelectAction($event)"
          [(ngModel)]="selectedButton"
          styleClass="mp-selectbutton"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>
        <div class="mb-2 flex flex-wrap gap-2">
          <div
            class="block md:hidden"
            *ngFor="let _selectedButton of selectButtonOptions"
          >
            <p-radioButton
              name="participant_type"
              [value]="_selectedButton.value"
              [(ngModel)]="selectedButton"
              [inputId]="_selectedButton.value"
              (ngModelChange)="onSelectAction($event)"
            ></p-radioButton>
            <label [for]="_selectedButton.value" class="ml-2">{{
              _selectedButton.label
            }}</label>
          </div>
        </div>
      </div>
      <p-skeleton
        width="100%"
        height="5rem"
        styleClass="mt-2"
        *ngIf="isSkeletonUserLoading"
      ></p-skeleton>
      <p-card
        *ngIf="participants.length && summary && !isSkeletonUserLoading"
        class="followers mx-auto block md:mx-0 md:my-auto"
      >
        <div class="followers-row">
          <div
            class="search-results"
            infinite-scroll
            [infiniteScrollDistance]="1"
            [infiniteScrollUpDistance]="2"
            [infiniteScrollThrottle]="500"
            [infiniteScrollContainer]="'.mat-drawer-content'"
            (scrolled)="onScrolledDown()"
            [fromRoot]="true"
          >
            <ul role="list" class="">
              <li
                class="grow-1 flex-stretch group mb-2 ml-12 flex items-center justify-between border-b pb-3 last:mb-0 last:border-b-0 last:pb-0"
                *ngFor="let huddler of participants"
              >
                <div class="grow-1 -ml-12 flex rounded-md px-1 py-1">
                  <p-avatar styleClass="mr-2" size="large" shape="circle">
                    <img
                      *ngIf="huddler?.user?.profilePicUrl"
                      [src]="
                        getProfileImageById(huddler?.user?.id || 0)
                          | image
                          | async
                      "
                      alt=""
                    />
                    <img
                      *ngIf="!huddler?.user?.profilePicUrl"
                      src="../assets/images/user-pic-default.svg"
                      alt="default"
                    />
                  </p-avatar>
                  <div class="flex flex-col">
                    <div>
                      <span *ngIf="huddler?.user"
                        >{{ huddler?.user?.displayName }}&nbsp;</span
                      >
                      <!-- Display the role with parentheses for all roles except 'UNDECIDED' -->
                      <span
                        *ngIf="huddler?.role && huddler?.role !== 'UNDECIDED'"
                      >
                        ({{ roles && roles[huddler?.role || ""] }})
                      </span>
                    </div>
                    <div
                      class="truncate"
                      *ngIf="huddler?.user?.type === 'USER'"
                    >
                      <a
                        [routerLink]="['/', huddler?.user?.username]"
                        class="primary transition duration-300"
                        >&commat;{{ huddler?.user?.username }}</a
                      >
                    </div>
                    <div
                      class="truncate"
                      *ngIf="huddler?.user?.type === 'GUEST'"
                    >
                      <span class="primary">{{ huddler?.user?.email }}</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </p-card>
      <div
        *ngIf="(!participants.length || !summary) && !isSkeletonUserLoading"
        class="mt-4 ps-2"
      >
        <span *ngIf="selectedButton === 'confirmed'"
          >No one confirmed yet.</span
        >
        <span *ngIf="selectedButton === 'tenative'">No one is tenative.</span>
        <span *ngIf="selectedButton === 'interested'"
          >No one has shown any interest yet.</span
        >
        <span *ngIf="selectedButton === 'declined'">No one declined.</span>
        <span *ngIf="selectedButton === 'waiting_list'"
          >No one is in Waiting List.</span
        >
        <span *ngIf="selectedButton === 'invites'">No invited user.</span>
      </div>
    </div>
  </div>
</div>
