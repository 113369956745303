import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MenuItem } from 'primeng/api';
import { HuddleService } from 'src/app/main/services/huddle.service';
import { VehicleService } from 'src/app/main/services/vehicle.service';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import {
  faUsers,
  faUser,
  faCar,
  faAngleDown,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import {
  Carpool,
  DialogEvent,
  Huddle,
  HuddleSettings,
  TripType,
  User,
  Vehicle,
} from 'src/app/main/types/main.types';
import { initMap } from 'src/app/main/utilities';
import {
  moreOptions,
  goingOptions,
  requestedOptions,
} from 'src/app/main/utilities/overlayHelper';
import { UserDetails } from 'src/app/main/types/main.types';
import { Subject, Observable, tap } from 'rxjs';
import { AppState } from 'src/app/main/store/model/state.model';
import { environment } from 'src/environments/environment';
import { ActivityEnum } from 'src/app/main/utilities';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { formatEventDate } from 'src/app/main/utilities';

enum TripTypeEnum {
  R = 'ROUND',
  D = 'DEPART',
  RT = 'RETURN',
}

@Component({
  selector: 'app-huddle-view',
  templateUrl: './huddle-view.component.html',
})
export class HuddleViewComponent implements OnInit {
  carpoolVisible = false;
  coverPhotoUrl: string;
  submitted = false;
  carpoolFormGroup!: FormGroup;
  faUsers: IconDefinition = faUsers;
  faUser: IconDefinition = faUser;
  faCar: IconDefinition = faCar;
  faStar: IconDefinition = faStar;
  faAngleDown: IconDefinition = faAngleDown;
  tabs: MenuItem[] = [];
  activeTab: MenuItem | undefined;
  eventDate: number;
  eventLongDate: Date;
  eventLongDateEnd: Date | null;
  huddleView: Huddle;
  isRoundOrDepart = false;
  isReturn = false;
  vehicles: Vehicle[] = [];
  tripType: TripType[];
  startDate: Date = new Date();
  huddleId: string | null;
  carpools: any;
  startAddressSubject: Subject<any> = new Subject<any>();
  endAddressSubject: Subject<any> = new Subject<any>();
  items: MenuItem[] | undefined;
  isCreate = true;
  isViewMode = false;
  moreOptions: any = moreOptions;
  goingOptions: any = goingOptions;
  leaderName: string;
  profilePic: string;
  userDetail$: Observable<UserDetails>;
  showInterestBtn = true;
  interested = false;
  rsvpType = '';
  prevRsvpType = '';
  userInfo: UserDetails;
  canCreateCarpool: boolean;
  isLoading = true;
  isPastHuddle = false;
  inviteVisible = false;
  requestedOptions = requestedOptions;
  activityEnum = ActivityEnum;
  menuId: string;
  prevTab: MenuItem | undefined;
  selectedButtonTab: string;
  isCarpoolAllowed = false;
  guestVisible = false;
  selectedButtonGuests: any[];
  selectedGuest: any;
  adult = 0;
  teens = 0;
  kids = 0;
  toddlers = 0;
  seniors = 0;
  settings: HuddleSettings;
  huddleViewEvent: Subject<Huddle> = new Subject<Huddle>();
  dialogEvent: Subject<DialogEvent> = new Subject<DialogEvent>();
  viewCarpoolEvent: Subject<any> = new Subject<any>();
  huddleParticipantEvent: Subject<string> = new Subject<string>();
  shareLinkEvent: Subject<boolean> = new Subject<boolean>();
  @ViewChild('rsvp', { static: false }) rsvpDialog: ConfirmDialog;

  constructor(
    private huddleService: HuddleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private vehicleService: VehicleService,
    private store: Store<AppState>,
    private confirmationService: ConfirmationService,
  ) {
    this.tabs = [
      { label: 'Overview' },
      { label: 'Participants' },
      { label: 'Carpools' },
      { label: 'Messages' },
      { label: 'Settings' },
    ];
    this.selectedButtonGuests = [
      { label: 'Confirm', value: 'YES' },
      { label: 'Tentative', value: 'MAYBE' },
    ];
    this.selectedGuest = 'YES';
  }

  ngOnInit(): void {
    this.huddleId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getHuddle();
    this.getVehicles();
    this.tripType = [
      { name: 'Round', code: TripTypeEnum.R },
      { name: 'Depart', code: TripTypeEnum.D },
      { name: 'Return', code: TripTypeEnum.RT },
    ];
    this.carpoolFormGroup = this.formBuilder.group({
      vehicle: [null, Validators.required],
      tripType: [null, { disabled: true }],
      oneWayAllowed: [false],
      joinRequestRequired: [false],
      startTime: [null],
      returnTime: [null],
      notes: [''],
      startAddress: [null],
      endAddress: [null],
      externalCommChannelUrl: [''],
    });
    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.userInfo = user;
          this.leaderName = `${user.firstName || ''} ${user.lastName || ''}`;
          this.profilePic = user.profilePicUrl;
        }),
      );
    this.userDetail$.subscribe();
  }

  getHuddle() {
    this.huddleService.getHuddle(this.huddleId).subscribe({
      next: (data: any) => {
        const { startDateTime, endDateTime, features } = data;
        const startDate = new Date(startDateTime);
        this.isCarpoolAllowed = features.some(
          (u: any) => u.enabled && u.key === 'CARPOOLING',
        );
        this.huddleView = data;
        this.settings = data.settings;
        this.coverPhotoUrl =
          environment.apiUrl + '/api/huddles/' + data.id + '/cover';
        if (data.selfHuddler != null) {
          this.interested = data.selfHuddler.interested;
          this.rsvpType = data.selfHuddler.rsvpType;
          if (
            this.rsvpType != null &&
            (this.rsvpType == 'YES' || this.rsvpType == 'MAYBE')
          ) {
            this.showInterestBtn = false;
          }
        }
        this.moreOptions.forEach((g: any) => {
          g.id === 'interested' && (g.disabled = !this.showInterestBtn);
          g.id === 'cancel' &&
            this.userInfo.id !== data.host?.id &&
            (g.disabled = true);
        });
        data.settings.decisiveRsvp &&
          (this.goingOptions = this.goingOptions.filter(
            (going: any) => going.label !== 'Tentative',
          ));
        !this.isCarpoolAllowed &&
          (this.moreOptions = this.moreOptions.filter(
            (more: any) => more.id !== 'carpool',
          ));
        this.eventDate = startDate.getDate();
        this.eventLongDate = startDate;
        this.eventLongDateEnd = endDateTime ? new Date(endDateTime) : null;
        data.coHosts = data.coHosts || [];
        data.host && data.coHosts.push(data.host);
        this.huddleViewEvent.next(data);
        this.isPastHuddle = startDate < new Date();
        !this.isCarpoolAllowed &&
          (this.tabs = this.tabs.filter((tab) => tab.label !== 'Carpools'));
        !data.settings.participantVisible &&
          !data.coHosts.some((user: User) => user.id === this.userInfo.id) &&
          (this.tabs = this.tabs.filter((tab) => tab.label !== 'Participants'));
        this.isLoading = false;
        this.viewCarpoolEvent.next(this.huddleView);
        setTimeout(() => {
          if (!this.activeTab?.label) this.activeTab = this.tabs[0];
        }, 100);
      },
      error: (err) => {
        this.isLoading = false;
        console.error('Request to get huddle errored out.' + err.message);
      },
    });
  }

  onActiveItemChange(e: any) {
    if (this.prevTab?.label !== e?.label) {
      this.activeTab = e;
      this.prevTab = this.activeTab;
      if (e.label === 'Overview') {
        this.huddleViewEvent.next(this.huddleView);
      }

      if (e.label === 'Participants') {
        this.huddleParticipantEvent.next(e.label);
      }
    }
  }

  getVehicles() {
    this.vehicleService.getVehicles().subscribe({
      next: (data: any) => {
        this.vehicles = data;
      },
      error: (err) => {
        console.error('Request to get vehicles errored out.' + err.message);
      },
    });
  }

  openCarpoolDialog() {
    this.isCreate = true;
    this.isViewMode = false;
    this.dialogEvent.next({
      dialogType: 'CARPOOL',
      visible: true,
      isCreate: true,
      isViewMode: false,
      huddleCarpool: true,
      huddleObj: {
        id: this.huddleView.id,
        huddle: this.huddleView,
      },
    });
  }

  onStartAddressChange(e: any) {
    this.carpoolFormGroup.get('startAddress')?.patchValue(e);
    initMap('gmap-route').then((map) => {
      if (this.carpoolFormGroup.get('endAddress')?.value) {
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);
        this.calculateAndDisplayRoute(directionsService, directionsRenderer);
      }
    });
  }

  onEndAddressChange(e: any) {
    this.carpoolFormGroup.get('endAddress')?.patchValue(e);
    initMap('gmap-route').then((map) => {
      if (this.carpoolFormGroup.get('startAddress')?.value) {
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);
        this.calculateAndDisplayRoute(directionsService, directionsRenderer);
      }
    });
  }

  onTripTypeChange(e: any) {
    const { value } = e;
    const tripType = value.code;
    this.isRoundOrDepart =
      tripType === TripTypeEnum.D || tripType === TripTypeEnum.R;
    this.isReturn = tripType === TripTypeEnum.RT;
  }

  onSubmit() {
    const { tripType } = this.carpoolFormGroup.value;
    const carpool: Carpool = {
      ...this.carpoolFormGroup.value,
      parentEntity: 'HUDDLE',
      parentId: this.huddleId,
      tripType: tripType.code,
      seatingCapacity: 0,
      leader: {},
    };

    if (this.carpoolFormGroup.valid) {
      const method = this.isCreate ? 'createCarpool' : 'updateCarpool';
      this.submitted = true;
      this.huddleService[method](carpool).subscribe({
        next: (data: any) => {
          this.submitted = false;
          this.carpoolVisible = false;
          this.resetForm();
        },
        error: (err) => {
          this.submitted = false;
          console.error('Request to create carpool errored out.' + err.message);
        },
      });
    }
  }

  editCarpool(data: any) {
    this.canCreateCarpool = !data;
  }

  resetForm() {
    this.carpoolFormGroup.reset({
      vehicleName: null,
      tripType: null,
      startAddress: null,
      endAddress: null,
    });
    this.startAddressSubject.next({
      action: 'RESET',
    });
    this.endAddressSubject.next({
      action: 'RESET',
    });
  }

  calculateAndDisplayRoute(
    directionsService: google.maps.DirectionsService,
    directionsRenderer: google.maps.DirectionsRenderer,
  ) {
    const waypts: google.maps.DirectionsWaypoint[] = [];
    const _startAddress: string =
      this.carpoolFormGroup.get('startAddress')?.value.formattedAddress;
    const _endAddress: string =
      this.carpoolFormGroup.get('endAddress')?.value.formattedAddress;
    directionsService
      .route({
        origin: _startAddress,
        destination: _endAddress,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        directionsRenderer.setDirections(response);
      });
  }

  renderAddress() {
    this.startAddressSubject.next({
      action: 'FORM',
      payload: {
        startAddress: this.carpoolFormGroup.get('startAddress')?.value,
      },
    });
    this.endAddressSubject.next({
      action: 'FORM',
      payload: {
        endAddress: this.carpoolFormGroup.get('endAddress')?.value,
      },
    });
    this.onEndAddressChange(this.carpoolFormGroup.get('endAddress')?.value);
  }

  get f() {
    return this.carpoolFormGroup.controls;
  }

  generateUrlToShare() {}

  getUsersToInvite() {}

  inviteUsers() {}

  saveRsvp(rsvpType: string) {
    this.prevRsvpType = rsvpType;

    const {
      settings: { guestAllowed },
      paymentMode,
    } = this.huddleView;

    if ((guestAllowed || paymentMode === 'PAID') && rsvpType !== 'NO') {
      this.guestVisible = true;
      this.selectedGuest = rsvpType;
    } else if (
      this.isCarpoolAllowed &&
      this.canCreateCarpool &&
      rsvpType !== 'YES'
    )
      this.confirmationService.confirm({
        accept: () => {},
        reject: () => {},
      });
    else this.onRSVPClick(this.prevRsvpType, null);
  }

  onRSVPClick(rsvpType: string, guests: any) {
    this.rsvpDialog.reject();
    this.huddleService
      .rsvp({
        huddleId: this.getId(this.huddleId),
        rsvpType,
        ...(guests && { guests }),
      })
      .subscribe({
        next: (data: any) => {
          console.log('Request to RSVP the huddle is successful.');
          if (!data.error) {
            this.moreOptions.forEach((g: any) => {
              g.id === 'interested' && (g.disabled = !this.showInterestBtn);
            });
            this.messageService.add({
              severity: 'success',
              summary: 'Huddle RSVP',
              detail: data.message,
            });
            this.guestVisible = false;
            this.isLoading = false;
            this.getHuddle();
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Huddle RSVP',
              detail: data.message,
            });
          }
        },
        error: (e) => {
          console.error('Request to RSVP the huddle  errored out.');
          console.error(e);
        },
        complete: () => console.info('Request to RSVP the huddle completed.'),
      });
  }

  saveInterest(interested: boolean) {
    this.huddleService
      .saveInterest(this.getId(this.huddleId), interested)
      .subscribe({
        next: (data: any) => {
          console.log('Request to join the huddle is successful.');
          if (!data.error) {
            this.interested = interested;
          }
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Huddle Interest',
            detail: data.message,
          });
        },
        error: (e) => {
          console.error('Request to join the huddle  errored out.');
          console.error(e);
        },
        complete: () => console.info('Request to join the huddle completed.'),
      });
  }

  onHuddleData(e: any) {
    this.viewCarpoolEvent.next(e);
  }

  getId(idStr: string | null) {
    let id = 0;
    if (idStr) {
      id = parseInt(idStr);
    }
    return id;
  }

  onClickCarpool() {
    this.activeTab = this.tabs.find((tab) => tab.label === 'Carpools');
  }

  onOverlayMenuClick(id: string) {
    this.menuId = id;
    if (id === 'copy_huddle_link') {
      this.shareLinkEvent.next(!0);
    } else if (id === 'interested') {
      this.saveInterest(!this.interested);
    } else if (id === 'carpool') {
      this.activeTab = this.tabs.find((tab) => tab.label === 'Carpools');
    } else if (id === 'cancel') {
      this.confirmationService.confirm({
        accept: () => {},
        reject: () => {},
      });
    } else if (id === 'invite') {
      this.inviteVisible = true;
    } else if (id === 'withdraw') {
      this.requestWithdraw();
    }
  }

  onView(e: any) {
    this.canCreateCarpool = e.carpooling;
  }

  cancelHuddle() {
    this.huddleService.cancelHuddle('' + this.huddleView.id).subscribe({
      next: (data: any) => {
        this.router.navigate(['/huddle', 'huddles']);
        this.messageService.add({
          severity: 'success',
          summary: 'Cancel Huddle',
          detail: 'Huddle has been cancelled',
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Cancel Huddle',
          detail: err.error.message,
        });
      },
    });
  }

  invite(e: any) {
    this.huddleService.invite('' + this.huddleId, e).subscribe({
      next: (data: any) => {
        this.messageService.add({
          severity: data.error ? 'error' : 'success',
          summary: 'Invite Huddle',
          detail: data.message,
        });
        this.inviteVisible = false;
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Invite Huddle',
          detail: err.error.message,
        });
      },
    });
  }

  addGuest() {
    this.isLoading = true;
    const _guests: any = {};
    this.adult > 0 && (_guests['ADULT'] = this.adult);
    this.teens > 0 && (_guests['TEEN'] = this.teens);
    this.kids > 0 && (_guests['KID'] = this.kids);
    this.toddlers > 0 && (_guests['TODDLER'] = this.toddlers);
    this.seniors > 0 && (_guests['SENIOR'] = this.seniors);
    this.onRSVPClick(
      this.selectedGuest,
      Object.keys(_guests).length > 0 ? _guests : null,
    );
  }

  requestWithdraw() {
    this.huddleService
      .deleteRequestOrWaiting({
        huddleId: '' + this.huddleId,
      })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Huddle Public',
            detail: data.message,
          });
          this.getHuddle();
        },
        error: (err) => this.handleCatch(err),
      });
  }

  handleCatch(err: any) {
    this.messageService.add({
      severity: 'error',
      summary: 'Huddle Public',
      detail: err.error.message || err.message,
    });
  }

  onHuddlers(data: any) {
    this.activeTab = this.tabs.find((tab) => tab.label === 'Participants');
    this.selectedButtonTab = data;
  }

  formatDate(startDate: Date, endDate: Date | null) {
    return formatEventDate(
      startDate.toISOString(),
      endDate?.toISOString() || '',
    );
  }

  onRsvpChange(data: { error: boolean; message: string; rsvpType: string }) {
    if (!data.error) {
      this.rsvpType = data.rsvpType;
    }
  }
}
